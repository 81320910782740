import classes from "../assets/styles/AddNewEmergencyContact.module.css";
import addcontact from "../assets/images/home_page_images/addcontact.svg";
import EmergencyAddEditModal from "./Modals/EmergencyAddEditModal";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import showToast from "../helpers/showToast";
import { subscribedPackage } from "../utils/store";
import FreePackageCompleteModal from "./Modals/FreePackageCompleteModal";
import { useAtom } from "jotai";
import { useState } from "react";

const AddNewEmergencyContact = ({
  show,
  loading,
  handleSaveShowModal,
  selectedData,
  setSelectedData,
  handleOpenModel,
  handleCloseModal,
  homeListing,
}) => {
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);
  const { compareLimits, updateCount, manageFreePackage } = useFunctionsCount();
  const handleShowEmergencyContactModal = () => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(
        functionTypes.EMERGENCY_CONTACT
      );
      if (checkFreePackage) {
        setSameListing(
          checkFreePackage === functionTypes.EMERGENCY_CONTACT || false
        );
        handleFreePackageModal();
        return false;
      }
    } else {
      const isLimitExceeded = compareLimits(functionTypes.EMERGENCY_CONTACT);
      if (isLimitExceeded)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
    }
    handleOpenModel();
  };

  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  return (
    <>
      <div
        className={classes.addContentWrapper}
        onClick={handleShowEmergencyContactModal}
      >
        <img src={addcontact} alt="" />
        <p>Add Contact</p>
      </div>

      <EmergencyAddEditModal
        loading={loading}
        show={show}
        data={selectedData}
        setSelectedData={setSelectedData}
        handleSaveShowModal={handleSaveShowModal}
        handleCloseModal={handleCloseModal}
      />

      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={homeListing}
      />
    </>
  );
};

export default AddNewEmergencyContact;
