import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowCustomURLSFunctions from "../../components/PageComponents/Function/ShowCustomURLSFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import { FILE_UPLOAD } from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FileUploadAddEditModal from "../../components/Modals/FileUploadAddEditModal";
import addcontact from "../../assets/images/home_page_images/addcontact.svg";
import { Link } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import { formatUrl } from "../../utils/functions/index";
import _ from "lodash";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import ShowFileUpload from "../../components/PageComponents/Function/ShowFileUpload";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import FreePackageCompleteModal from "../../components/Modals/FreePackageCompleteModal";

const FileUpload = () => {
  let emptyFilesData = {
    isNew: true,
    id: "",
    title: "",
    url_path: "",
  };
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [files, setFiles] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyFilesData);
  const [filterText, setFilterText] = useState("");
  const { compareLimits, updateCount, manageFreePackage } = useFunctionsCount();
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleShowFileUploadModal = () => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(functionTypes.FILE_UPLOAD);
      if (checkFreePackage) {
        setSameListing(checkFreePackage === functionTypes.FILE_UPLOAD || false);
        handleFreePackageModal();
        return false;
      }
    } else {
      const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
      if (isLimitExceeded)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
    }
    setShowModal(true);
  };

  const handleSaveShowModal = async () => {
    setLoading(true);

    let payload = _.cloneDeep(selectedData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};
    if (selectedData.id) {
      result = await put(
        FILE_UPLOAD + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        FILE_UPLOAD,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!selectedData.id) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.ADD);
      }
      setLoading(false);

      let filesArr = _.cloneDeep(files);

      // On Update
      if (selectedData.id) {
        const index = files.findIndex((el) => el.id == selectedData.id);
        filesArr[index] = data;
      } else {
        filesArr.unshift(data);
      }

      setFiles(filesArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyFilesData);
    setShowModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }
    let { status, message } = await deleteRecord(
      `${FILE_UPLOAD}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getFiles();
      updateCount(functionTypes.FILE_UPLOAD, functionTypes.DELETE);
      setLoading(false);
    }
    setLoading(false);
  };

  // Get all custom urls
  const getFiles = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${FILE_UPLOAD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setFiles(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFiles();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = files.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.url_path || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, files]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link onClick={handleGoBack} style={{ color: "#ffffff" }}>
                Functions
              </Link>
              {` > File Upload`}
            </h6>

            {files.length !== 0 ? (
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            ) : null}
          </div>

          {files.length === 0 ? (
            <EmptyListingBanner
              headLineText="URLs for your File Uploads"
              title="Create URL"
              onClick={handleShowFileUploadModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowFileUploadModal}>
                      <div className={classes.addContentWrapper}>
                        <img src={addcontact} alt="" />
                        <p>Create URL</p>
                      </div>
                    </span>
                  ) : null}
                </div>

                <ShowFileUpload
                  loading={loading}
                  show={showModal}
                  files={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          )}

          {/* Custom url modals */}
          {showModal && (
            <FileUploadAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
      />

      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={getFiles}
      />
    </>
  );
};

export default FileUpload;
