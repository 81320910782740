import React, { useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Home/AddNewCard.module.css";
import addnewcard from "../../../assets/images/home_page_images/addnewcard.svg";
import useFunctionsCount from "../../../hooks/useFunctionsCount";
import functionTypes from "../../../utils/constants/functionTypes";
import showToast from "../../../helpers/showToast";
import { useNavigate } from "react-router-dom";
import FreePackageCompleteModal from "../../Modals/FreePackageCompleteModal";
import { subscribedPackage } from "../../../utils/store";
import { useAtom } from "jotai";

const AddNewCard = ({ teamId, userId, team_id, user_id, cardListing }) => {
  const { compareLimits, manageFreePackage } = useFunctionsCount();
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(functionTypes.CARD);
      if (checkFreePackage) {
        setSameListing(checkFreePackage === functionTypes.CARD || false);
        handleFreePackageModal();
        return false;
      }
    } else {
      const res = compareLimits(functionTypes.CARD);
      if (res)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );

      let link =
        teamId && userId
          ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions`
          : `/function/cardfunctions${
              team_id && user_id ? `?team_id=${team_id}&user_id=${user_id}` : ""
            }`;
      navigate(link);
    }
  };

  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  return (
    <>
      <a onClick={handleNavigation}>
        <div className={classes.cardWrapper}>
          <img src={addnewcard} alt="" />
          <p>Add New Card</p>
        </div>
      </a>

      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={cardListing}
      />
    </>
  );
};

export default AddNewCard;
