import React from "react";
import baseURL from "../../utils/constants/baseUrl";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import { Image } from "react-bootstrap";
import classes from "../../assets/styles/ManageNodes.module.css";
import { TreeNode } from "react-organizational-chart";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  CARD_SHARE,
  EMEGENCY_CONTACT_SHARE,
} from "../../utils/constants/common";

const RenderTree = ({
  node,
  setShowModal,
  handleNodeClick,
  handleShowModal,
  handleDelete,
  handleQrOpen,
  handleShowOtherMembersModal,
  handleShowAllNodesModal,
  unassignedMembers,
  setOpenTeamMembersModal,
}) => {
  return (
    <TreeNode
      label={
        <div className="manageNodesDropdownWrapper">
          <div className="dropdown">
            <button
              className="btn customActionDropdown dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <div
                    className={classes.nodeBox}
                    onClick={() => handleNodeClick(node)}
                  >
                    {node.name}
                  </div> */}

              <div
                className={classes.memberNodeBox}
                onClick={() => handleNodeClick(node)}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <div className={classes.teamMemberImage}>
                      <div className={classes.nodeImage}>
                        {node?.name?.charAt(0)}
                      </div>
                    </div>
                    <div>
                      <p className={classes.nodeName}>{node.name}</p>

                      <p className={classes.nodeDetail}>
                        {node?.teamMembers?.length
                          ? `Members:
                                ${node?.teamMembers.length}`
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className={classes.threedotsWrapper}>
                    <BsThreeDotsVertical />
                  </div>
                </div>
              </div>
            </button>
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleQrOpen(node.uuid)}
                >
                  QR Code
                </span>
              </li>
              {node?.teamMembers?.length > 0 ? (
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => setOpenTeamMembersModal(true)}
                  >
                    View Team Members
                  </span>
                </li>
              ) : null}

              {unassignedMembers.length > 0 ? (
                <li>
                  <span
                    className="dropdown-item"
                    onClick={handleShowOtherMembersModal}
                  >
                    Attach Team Member
                  </span>
                </li>
              ) : null}

              <li>
                <span
                  className="dropdown-item"
                  onClick={() => setShowModal(true)}
                >
                  Create
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleShowAllNodesModal(node)}
                >
                  Reassign the node
                </span>
              </li>

              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleShowModal(node)}
                >
                  Edit
                </span>
              </li>

              <li>
                <span
                  className="dropdown-item"
                  style={{ color: "#E35252" }}
                  onClick={() => handleDelete(node.id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          </div>
        </div>
      }
    >
      {node?.children &&
        node?.children.map((child) => (
          <RenderTree
            key={child.id}
            node={child}
            handleShowModal={handleShowModal}
            setShowModal={setShowModal}
            handleNodeClick={handleNodeClick}
            handleDelete={handleDelete}
            handleQrOpen={handleQrOpen}
            handleShowOtherMembersModal={handleShowOtherMembersModal}
            handleShowAllNodesModal={handleShowAllNodesModal}
            unassignedMembers={unassignedMembers}
            setOpenTeamMembersModal={setOpenTeamMembersModal}
          />
        ))}
    </TreeNode>
  );
};

export default RenderTree;
