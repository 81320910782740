import Home from "./pages/Home";
import Contact from "./pages/Contact";
import EditContact from "./pages/EditContact";
import CreateManualContact from "./pages/CreateManualContact";
import Function from "./pages/Function/Function";
import Settings from "./pages/Settings/Settings";
import Help from "./pages/Help/Help";
import HelpTutorial from "./pages/Help/Tutorial";
import HelpFaqs from "./pages/Help/Faqs";
import ContactUsFaqs from "./pages/Help/ContactUs";
import CardFunctions from "./pages/CardFunctions";
import Cards from "./pages/Card";
import EditCardFunctions from "./pages/EditCardFunctions";
import ViewEmergencyContact from "./pages/Function/ViewEmergencyContact";
import DashboardLayout from "./layout/DashboardLayout";
import EmergencyContacts from "./pages/Function/EmergencyContacts";
import AboutUs from "./pages/Help/AboutUs";
import Pages from "./pages/Settings/Pages";
import AllPackageContacts from "./pages/admin/package/AllPackageContacts";
import AddEditPackageContact from "./pages/admin/package/AddEditPackageContact";
import AddNewPage from "./pages/Settings/AddNewPage";
import SendNotification from "./pages/admin/notification/SendNotification";
import AppSettings from "./pages/admin/setting/AppSettings";
import Pricing from "./pages/admin/pricing/AllPricing";
import AddEditPricing from "./pages/admin/pricing/AddEditPricing";
import Tutorial from "./pages/admin/tutorial/AllTutorial";
import AddEditTutorial from "./pages/admin/tutorial/AddEditTutorial";
import AdminUser from "./pages/admin/adminUser/AllAdminUsers";
import AddEditAdminUser from "./pages/admin/adminUser/AddEditAdminUser";
import ContactUs from "./pages/admin/contactUs/AllContactUs";
import AddEditContactUs from "./pages/admin/contactUs/AddEditContactUs";
import AllCustomUrls from "./pages/admin/customUrl/AllCustomUrls";
import AllVenmos from "./pages/admin/venmo/AllVenmos";
import AllCashApps from "./pages/admin/cashApp/AllCashApps";
import AllEmergencyContacts from "./pages/admin/emergencyContact/AllEmergencyContacts";
import AllCards from "./pages/admin/cards/AllCards";
import AddEditCard from "./pages/admin/cards/AddEditCard";
import Faqs from "./pages/admin/faqs/AllFaqs";
import AddEditFaqs from "./pages/admin/faqs/AddEditFaqs";
import Users from "./pages/admin/user/AllUsers";
import AddEditUser from "./pages/admin/user/AddEditUser";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { get } from "./server";
import { PACKAGES_LISTING, GET_USER_ACTION } from "./utils/constants/apiUrl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardView from "./pages/CardView";
import EmergencyContactsListing from "./pages/Function/EmergencyContacts";
import CustomURLSListing from "./pages/Function/CustomURLSListing";
import CashAppListing from "./pages/Function/CashAppListing";
import VenmoListing from "./pages/Function/VenmoListing";
import { useAtom } from "jotai";
import {
  loggedInUser,
  isOwnerPackage,
  teamIDCheck,
  userIDCheck,
  subscriptionPackages,
  subscribedPackage,
  accountType,
} from "./utils/store";
import RequireAuth from "./components/RequireAuth";
import CheckTeamOwnerPackage from "./components/CheckTeamOwnerPackage";
import Layout from "./layout/Layout";
import Missing from "./components/Missing";
import { Routes, Route } from "react-router-dom";
import Unauthorized from "./components/Unauthorized";
import HelpContactUs from "./pages/Help/ContactUs";
import ForgotPassword from "./pages/ForgotPassword";
import ScrollToTop from "./components/ScrollToTop";
import TermsAndCondition from "./pages/Help/TermsAndCondition";
import PrivacyAndPolicy from "./pages/Help/PrivacyAndPolicy";
import ExchangePolicy from "./pages/Help/ExchangePolicy";
import PublicCard from "./pages/PublicCard";
import PublicContact from "./pages/PublicContact";
import ViewContact from "./pages/ViewContact";
import PublicEmergencyContact from "./pages/PublicEmergencyContact";
import PublicAlreadyMemberSetup from "./pages/PublicAlreadyMemberSetup";
import PublicUserNFC from "./components/PageComponents/Function/PublicUserNFC";
import AppleAppSiteAssociation from "./pages/apple-app-site-association";
import IntellectualProperty from "./pages/Help/IntellectualProperty";
import FileUpload from "./pages/Function/FileUplaod";
import AllFileUpload from "./pages/admin/fileUpload/AllFileUpload";
import AllPayments from "./pages/admin/payments/AllPayments";
import PaymentListing from "./pages/Function/PaymentListing";
import TeamMembers from "./pages/Team/TeamMembers";
import AddEditTeamMember from "./pages/Team/AddEditTeamMember";
import AllTeamOwners from "./pages/admin/team/AllTeamOwners";
import TeamDetail from "./pages/admin/team/TeamDetail";
import EditTeamMember from "./pages/admin/team/EditTeamMember";
import Invitations from "./pages/Team/Invitations";
import PendingMemberPayments from "./pages/Team/PendingMemberPayments";
import Team from "./pages/Team/Team";
import ManageNodes from "./pages/Team/ManageNodes";
import ViewHierarchy from "./pages/Team/ViewHierarchy";
import EditTeamOwner from "./pages/admin/team/EditTeamOwner";
import Subscription from "./pages/Subscription";
import PublicNodes from "./pages/Team/PublicNodes";
import PublicFunctions from "./pages/Team/PublicFunctions";
import React, { useEffect } from "react";
import PublicTeamNode from "./pages/Team/PublicTeamNode";
import Stats from "./pages/Team/Stats";
import PublicFunctionsQR from "./pages/PublicFunctionsQR";
import showToast from "./helpers/showToast";
import { getUserSubscriptionPlan } from "./utils/functions";
import SubscriptionPackages from "./components/PageComponents/Settings/Subscription";
import PackageContactForm from "./pages/PackageContactForm";
import PaymentNotPaid from "./components/PaymentNotPaid";
import SocialSignup from "./pages/SocialSignup";
import useFunctionsCount from "./hooks/useFunctionsCount";
import useRefreshUser from "./hooks/useRefreshUser";
import CheckSubscriptionExpiry from "./components/CheckSubscriptionExpiry";

function App() {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [purchasePackages, setPurchasePackages] = useAtom(subscriptionPackages);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const { userActions } = useFunctionsCount();
  const { refreshUser } = useRefreshUser();

  const fetchAllPckages = async () => {
    let { status, message, data } = await get(PACKAGES_LISTING);
    if (status) {
      setPurchasePackages(data);
    } else {
      showToast(status, message);
    }
  };

  useEffect(() => {
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      refreshUser();
    }
  }, []);

  useEffect(() => {
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      if (loggedUser.role?.includes("team_admin") && !personalAccount) {
        const teamData = loggedUser?.secret_code?.filter(
          (item) => item.team_id !== null
        );

        setUserTeamId(teamData?.[0]?.team_id);
        setUserID(loggedUser?.connected_teams?.[0]?.user_id);
      }

      fetchAllPckages();
    }
  }, [loggedUser, personalAccount]);

  useEffect(() => {
    if (loggedUser?.package_details?.length > 0) {
      const packageWithTeam = loggedUser?.package_details?.filter(
        (obj) => obj.team_id !== null
      );

      const packageWithoutTeam = loggedUser?.package_details?.filter(
        (obj) => obj.team_id === null
      );

      if (!personalAccount) {
        const subcribedPackage = getUserSubscriptionPlan(
          packageWithTeam?.[0]?.package_id,
          purchasePackages
        );
        if (subcribedPackage) {
          setPurchasedPackage(subcribedPackage);
        } else {
          setPurchasedPackage({});
        }
      } else {
        const subcribedPackage = getUserSubscriptionPlan(
          packageWithoutTeam?.[0]?.package_id,
          purchasePackages
        );
        if (subcribedPackage) {
          setPurchasedPackage(subcribedPackage);
        } else {
          setPurchasedPackage({});
        }
      }
    }
  }, [purchasePackages]);

  const ROLES = {
    User: "customer",
    SuperAdmin: "super_admin",
    SubAdmin: "sub_admin",
    TeamOwner: "team_owner",
    TeamAdmin: "team_admin",
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* PuBLIC ROUTES */}
          <Route element={<Signup />} path="signup" />
          <Route element={<Login />} path="login" />
          <Route element={<SocialSignup />} path="socialSignup" />

          <Route
            element={<PublicFunctionsQR />}
            path="share/:functionType/:uuid"
          />

          <Route element={<ForgotPassword />} path="forgotpassword" />
          <Route
            element={<PackageContactForm />}
            path="PackageContactForm/:packageId"
          />
          {/* <Route element={<PublicCard />} path="share/card/:uuid" />
          <Route
            element={<PublicEmergencyContact />}
            path="share/emergencyContact/:uuid"
          />*/}
          {/* <Route element={<PublicContact />} path="share/contact/:uuid" /> */}
          <Route element={<PublicNodes />} path="qr/node/:uuid" />
          <Route element={<PublicTeamNode />} path="qr/team/:teamId" />

          <Route
            element={<PublicFunctions />}
            path="publicFunctions/:teamId/:userId"
          />
          <Route
            element={<AppleAppSiteAssociation />}
            path=".well-known/apple-app-site-association"
          />

          <Route element={<PublicAlreadyMemberSetup />} path="alreadyMember" />

          <Route element={<PublicUserNFC />} path="nfc/:uuid" />
          <Route path="unauthorized" element={<Unauthorized />} />

          {/* PRIVATE ROUTES */}

          <Route element={<RequireAuth allowedRoles={[ROLES.TeamOwner]} />}>
            <Route element={<Subscription />} path="subscription" />
          </Route>

          <Route element={<DashboardLayout />}>
            {/* FOR ADMIN USERS AND CUSTOMERS */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[
                      ROLES.SuperAdmin,
                      ROLES.SubAdmin,
                      ROLES.TeamOwner,
                      ROLES.TeamAdmin,
                      ROLES.User,
                    ]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                  {/* <CheckSubscriptionExpiry /> */}
                </>
              }
            >
              <Route index element={<Home />} />
            </Route>

            {/* FOR Team Owner */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[ROLES.TeamOwner, ROLES.TeamAdmin]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                </>
              }
            >
              {/* For his Team */}
              <Route path="team" element={<Team />} />
              <Route path="team/manageNodes" element={<ManageNodes />} />
              <Route path="team/teamMembers" element={<TeamMembers />} />
              <Route path="team/viewHierarchy" element={<ViewHierarchy />} />
              <Route path="team/stats" element={<Stats />} />
              <Route
                path="team/teamMembers/addEditTeamMember"
                element={<AddEditTeamMember />}
              />
              <Route
                path="team/teamMembers/addEditTeamMember/:userId"
                element={<AddEditTeamMember />}
              />
              <Route path="invitations" element={<Invitations />} />
              <Route element={<Subscription />} path="viewSubscription" />
              <Route
                path="pendingPayments"
                element={<PendingMemberPayments />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function"
                element={<Function />}
              />

              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cards"
                element={<Cards />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cardfunctions/:id"
                element={<EditCardFunctions />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cardfunctions"
                element={<CardFunctions />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/viewemergencycontact/:id"
                element={<ViewEmergencyContact />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/emergencycontacts"
                element={<EmergencyContacts />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/customurlslisting"
                element={<CustomURLSListing />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/fileupload"
                element={<FileUpload />}
              />

              <Route
                path="team/:teamId/teamMembers/member/:userId/function/paymentListing"
                element={<PaymentListing />}
              />
            </Route>
            {/* Shared Routes Between User and TeamOwner */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[
                      ROLES.TeamOwner,
                      ROLES.TeamAdmin,
                      ROLES.User,
                    ]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                  {/* <CheckSubscriptionExpiry /> */}
                </>
              }
            >
              <Route
                path="contacts/createManual"
                element={<CreateManualContact />}
              />
              <Route path="contacts/:id" element={<EditContact />} />
              <Route
                path="contacts/viewcontact/:id"
                element={<ViewContact />}
              />
              <Route path="contacts" element={<Contact />} />
              <Route path="function" element={<Function />} />
              <Route path="function/cards" element={<Cards />} />
              <Route
                path="function/cardfunctions/:id"
                element={<EditCardFunctions />}
              />
              <Route
                path="function/cardfunctions"
                element={<CardFunctions />}
              />
              <Route
                path="function/viewemergencycontact/:id"
                element={<ViewEmergencyContact />}
              />
              <Route
                path="function/emergencycontacts"
                element={<EmergencyContacts />}
              />
              <Route
                path="function/customurlslisting"
                element={<CustomURLSListing />}
              />
              <Route path="function/fileupload" element={<FileUpload />} />

              <Route
                path="function/paymentListing"
                element={<PaymentListing />}
              />

              {/* <Route path="function/venmoListing" element={<VenmoListing />} />
              <Route
                path="function/cashAppListing"
                element={<CashAppListing />}
              /> */}
              <Route
                path="function/emergencyContactslisting"
                element={<EmergencyContactsListing />}
              />
              <Route path="function/cardview" element={<CardView />} />
              <Route path="settings" element={<Settings />} />
              <Route path="packages" element={<SubscriptionPackages />} />
              <Route path="help" element={<Help />} />
              <Route path="help/helpContactUs" element={<HelpContactUs />} />
              <Route path="help/helpTutorial" element={<HelpTutorial />} />
              <Route path="help/helpFaqs" element={<HelpFaqs />} />
              <Route path="help/aboutus" element={<AboutUs />} />
              <Route
                path="help/termsandcondition"
                element={<TermsAndCondition />}
              />
              <Route
                path="help/privacyandpolicy"
                element={<PrivacyAndPolicy />}
              />
              <Route path="help/exchangepolicy" element={<ExchangePolicy />} />
              <Route
                path="help/intellectualproperty"
                element={<IntellectualProperty />}
              />
            </Route>
            {/* FOR CUSTOMER */}
            {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
             
            </Route> */}
            {/* FOR SUPER ADMIN AND SUB ADMIN */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.SuperAdmin, ROLES.SubAdmin]}
                />
              }
            >
              {/* Admin Cards*/}
              <Route path="cards" element={<AllCards />} />
              <Route path="cards/AddEditCard" element={<AddEditCard />} />
              <Route
                path="cards/AddEditCard/:cardId"
                element={<AddEditCard />}
              />

              {/* admin custom Url ,Venmo,Cash App,Emergenct contact*/}
              <Route path="customUrls" element={<AllCustomUrls />} />
              <Route path="fileUpload" element={<AllFileUpload />} />
              <Route path="cashApp" element={<AllCashApps />} />
              <Route path="venmos" element={<AllVenmos />} />
              <Route path="payments" element={<AllPayments />} />
              <Route
                path="emergenctContacts"
                element={<AllEmergencyContacts />}
              />

              {/* Tutorial section */}
              <Route path="tutorial" element={<Tutorial />} />
              <Route
                path="tutorial/addEditTutorial"
                element={<AddEditTutorial />}
              />
              <Route
                path="tutorial/addEditTutorial/:tutorialId"
                element={<AddEditTutorial />}
              />

              {/* FAQS */}
              <Route path="faqs" element={<Faqs />} />
              <Route path="faqs/addEditFaq" element={<AddEditFaqs />} />
              <Route path="faqs/addEditFaq/:faqId" element={<AddEditFaqs />} />

              {/*HELP CONTACT SECTION */}
              <Route path="contactUs" element={<ContactUs />} />
              <Route
                path="contactUs/AddEditContactUs"
                element={<AddEditContactUs />}
              />
              <Route
                path="contactUs/AddEditContactUs/:contactUsId"
                element={<AddEditContactUs />}
              />

              {/* pages */}

              <Route path="pages" element={<Pages />} />
              <Route path="pages/addnewpage/:pageId" element={<AddNewPage />} />
              <Route path="pages/addnewpage" element={<AddNewPage />} />

              <Route path="packageContacts" element={<AllPackageContacts />} />
              <Route
                path="packageContacts/:contactId"
                element={<AddEditPackageContact />}
              />
              {/* <Route path="pages/addnewpage/:pageId" element={<AddNewPage />} />
              <Route path="pages/addnewpage" element={<AddNewPage />} /> */}

              {/* users */}
              <Route path="users" element={<Users />} />
              <Route path="users/AddEditUser" element={<AddEditUser />} />
              <Route
                path="users/AddEditUser/:userId"
                element={<AddEditUser />}
              />
              {/* Team  */}

              <Route path="allTeamOwners" element={<AllTeamOwners />} />
              <Route path="allTeamOwners/:userId" element={<EditTeamOwner />} />
              <Route
                path="allTeamOwners/teamDetail/:teamId"
                element={<TeamDetail />}
              />
              <Route
                path="allTeamOwners/teamDetail/:teamId/editTeamMember/:teamMemberId"
                element={<EditTeamMember />}
              />

              {/* Prcincing/Subscription */}
              <Route path="pricing" element={<Pricing />} />
              <Route
                path="pricing/addEditPricing"
                element={<AddEditPricing />}
              />
              <Route
                path="pricing/addEditPricing/:priceId"
                element={<AddEditPricing />}
              />

              {/* Send Notification */}
              <Route path="sendNotification" element={<SendNotification />} />

              {/* App setting */}
              <Route path="appSetting" element={<AppSettings />} />
            </Route>
            {/* ONLY FOR ADMIN */}
            <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
              {/* Admin Users */}
              <Route path="adminUser" element={<AdminUser />} />
              <Route
                path="adminUser/AddEditAdminUser"
                element={<AddEditAdminUser />}
              />
              <Route
                path="adminUser/AddEditAdminUser/:adminId"
                element={<AddEditAdminUser />}
              />
            </Route>
          </Route>
          {/* No Record */}
          <Route path="*" element={<Missing />} />
          <Route path="paymentNotPaid" element={<PaymentNotPaid />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
