import { useState } from "react";
import classes from "../../assets/styles/Pages/Help/ContactUs.module.css";
import { BsTelephoneFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { post } from "../../server";
import { CONTACT_US } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { loggedInUser, globalAppSetting } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import { FiMail } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import FormInput from "../../components/Forms/FormInput";
import ContactUsSchema from "../../utils/SchemasValidation/ContactUsSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import contact_us from "../../assets/images/help/contact_us.svg";
import facebook from "../../assets/images/help/facebook.svg";
import twitter from "../../assets/images/help/twitterLogo.svg";
import linkedin from "../../assets/images/help/linkedin.svg";
import insta from "../../assets/images/help/insta.svg";
import tiktokicon from "../../assets/images/help/tiktokicon.svg";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import { validatePhoneWithCountryCode } from "../../utils/functions";

const ContactUs = () => {
  const [contactUs, setContactUs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [countryPhone, setCountryPhone] = useState("");
  const [loggedUser] = useAtom(loggedInUser);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContactUs({ ...contactUs, [name]: value });
  };

  const handlePhoneNumberChange = (value, country) => {
    setContactUs({ ...contactUs, phone_no: value });
    setCountryPhone(country.dialCode);
  };

  const validateForm = () => {
    let newErrors = ValidateSchema(contactUs, ContactUsSchema);

    if (contactUs.phone_no === "") {
      newErrors = { ...newErrors, phone_no: "Please enter your phone number" };
    }

    if (contactUs.phone_no.length > countryPhone.length) {
      let resultPhone = validatePhoneWithCountryCode(
        countryPhone,
        contactUs.phone_no
      );
      if (resultPhone) {
        newErrors = {
          ...newErrors,
          phone_no: "Please enter a valid phone number",
        };
      }
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setLoading(true);

    if (contactUs.phone_no && countryPhone.length > 0) {
      contactUs.phone_no = contactUs.phone_no.slice(
        countryPhone.length,
        contactUs.phone_no.length
      );
    }

    let payload = { ...contactUs };
    handlePhoneNumber(payload);
    payload.phone_no = `${countryPhone}${payload.phone_no}`;

    let { status, message } = await post(
      CONTACT_US,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      navigate("/help");
    }

    showToast(status, message);

    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* Tutorial top left section */}
          <div className="col-sm-6">
            <h5 className="helpHeading">Contact Us</h5>
            {/* <p className="helpSubHeading">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
              exercitationem quam repudiandae necessitatibus
            </p> */}

            <p className="helpDetail">
              Sometimes, you may need personalized assistance or have specific
              questions that aren’t covered in our FAQs or troubleshooting
              guide. Our dedicated support team is here to help. Reach out to us
              via email or through our contact form, and we’ll get back to you
              as soon as possible.
            </p>
            <div className={classes.socialMainWrapper}>
              <a href={appSetting.facebook_url} target="_blank">
                <div className={classes.socialWrapper}>
                  <img src={facebook} alt="" />
                </div>
              </a>
              <a href={appSetting.twitter_url} target="_blank">
                <div className={classes.socialWrapper}>
                  <img src={twitter} alt="" />
                </div>
              </a>
              <a href={appSetting.linkdin_url} target="_blank">
                <div className={classes.socialWrapper}>
                  <img src={linkedin} alt="" />
                </div>
              </a>
              <a href={appSetting.instagram_url} target="_blank">
                <div className={classes.socialWrapper}>
                  <img src={insta} alt="" />
                </div>
              </a>

              <a href={appSetting.tiktok_url} target="_blank">
                <div className={classes.socialWrapper}>
                  <img src={tiktokicon} alt="" />
                </div>
              </a>
            </div>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <img src={contact_us} alt="" className="helpImage" />
          </div>
        </div>
      </div>

      {/* Second Section */}

      <div className={classes.helpbottom}>
        <div className={classes.cardsWrapper}>
          {appSetting?.address && (
            <div className={classes.cardWrapper}>
              <HiLocationMarker />
              <span>{appSetting.address}</span>
            </div>
          )}

          {appSetting?.phone_number && (
            <div className={classes.cardWrapper}>
              <BsTelephoneFill />
              <span>
                {appSetting.country_phone ? `+${appSetting.country_phone}` : ""}
                {appSetting.phone_number || ""}
              </span>
            </div>
          )}

          {appSetting?.email && (
            <div className={classes.cardWrapper}>
              <FiMail />
              <span>{appSetting.email}</span>
            </div>
          )}
        </div>

        <div className={` ${classes.formWrapper}`}>
          <div className="row">
            <div className="col-md-6 col-12">
              <FormInput
                type="text"
                label="First Name"
                name="first_name"
                placeholder="First Name"
                value={contactUs.first_name}
                handleChange={handleChange}
                error={errors.first_name}
              />

              <FormInput
                type="text"
                label="Last Name"
                name="last_name"
                placeholder="Last Name"
                value={contactUs.last_name}
                handleChange={handleChange}
                error={errors.last_name}
              />

              <FormInput
                type="text"
                name="email"
                label="Email"
                placeholder="Email Address"
                value={contactUs.email}
                handleChange={handleChange}
                error={errors.email}
              />
              <label htmlFor="" className={classes.inputLabel}>
                Phone
              </label>
              <PhoneInput
                country={"us"}
                enableSearch={true}
                countryCodeEditable={false}
                inputClass={
                  errors.phone_no
                    ? "phoneNumberInput errorInput"
                    : "phoneNumberInput"
                }
                value={contactUs.phone_no}
                onChange={(value, country) =>
                  handlePhoneNumberChange(value, country)
                }
                // onChange={(value, country, e, formattedValue) => {
                //   setContactUs({
                //     ...contactUs,
                //     phone_no: value,
                //     countryPhone: `+${country.dialCode}`,
                //   });
                // }}
              />
              <p className="errorText">{errors.phone_no || ""}</p>
              {/* <FormInput
                type="number"
                name="phone_no"
                label="Phone"
                placeholder="Phone Number"
                value={contactUs.phone_no}
                handleChange={handleChange}
                error={errors.phone_no}
              /> */}
            </div>

            <div className="col-md-6 col-12">
              <FormInput
                type="text"
                name="subject"
                label="Subject"
                placeholder="Subject"
                value={contactUs.subject}
                handleChange={handleChange}
                error={errors.subject}
              />

              <div>
                <label className={classes.inputLabel}>Message</label>
                <textarea
                  placeholder="Enter your message."
                  name="message"
                  value={contactUs.message}
                  onChange={handleChange}
                  className={
                    errors.message
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <span className="errorText">{errors.message}</span>
              </div>
              <div className={classes.btnWrapper}>
                <button className={classes.sendBtn} onClick={handleSubmit}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style type="text/css">
        {`
 .react-tel-input .flag-dropdown.open{
  z-index: 27;
 }
	`}
      </style>
    </>
  );
};

export default ContactUs;
