import React from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Function/FunctionCard.module.css";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedInUser, accountType } from "../../../utils/store";
const FunctionCard = ({
  bgColor,
  image,
  title,
  description,
  url,
  teamId,
  userId,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);

  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.cardWrapper}>
          <div className={classes.imgWrapper}>
            <div className={classes.coverImg}>
              <div
                style={{ background: bgColor }}
                className={classes.circle}
              ></div>
            </div>
            <div className={classes.profileImg}>
              <Image
                src={image}
                alt="profile-img"
                loading="lazy"
                decoding="async"
              />
            </div>
          </div>
          <div className={classes.infoWrapper}>
            <div className={classes.name}>{title}</div>
            <div className={classes.profession}>{description}</div>
          </div>

          <div className={classes.btnWrapper}>
            <Link
              to={
                teamId && userId
                  ? `/team/${teamId}/teamMembers/member/${userId}${url}`
                  : `${url}${
                      loggedUser?.role?.includes("team_owner") &&
                      !personalAccount
                        ? `?team_id=${
                            Object.keys(loggedUser).length !== 0
                              ? loggedUser.teams.id
                              : null
                          }&user_id=${
                            Object.keys(loggedUser).length !== 0
                              ? loggedUser.teams.user_id
                              : null
                          }`
                        : ""
                    }`
              }
            >
              <button className={classes.createBtn}>Create</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default FunctionCard;
