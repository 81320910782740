import {
  teamIDCheck,
  subscribedPackage,
  userRemainingFunctions,
  memberRemainingFunctions,
} from "../utils/store";

import { useAtom } from "jotai";

const useFunctionsCount = () => {
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
    userRemainingFunctions
  );

  const [memberAvailableFunctions, setMemberAvailableFunctions] = useAtom(
    memberRemainingFunctions
  );

  const userActions = (userData) => {
    const actionInfoWithTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id !== null
    );
    const actionInfoWithoutTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id === null
    );

    setMemberAvailableFunctions({
      ...memberAvailableFunctions,
      total_cards: actionInfoWithTeam?.[0]?.total_cards,
      total_connections: actionInfoWithTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithTeam?.[0]?.total_payments,
    });

    setUserAvailableFunctions({
      ...userAvailableFunctions,
      total_cards: actionInfoWithoutTeam?.[0]?.total_cards,
      total_connections: actionInfoWithoutTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithoutTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithoutTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithoutTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithoutTeam?.[0]?.total_payments,
    });
  };

  const compareLimits = (functionType) => {
    // Get the limit value from data based on the functionType
    const limitValue =
      purchasedPackage?.[
        `limit_${
          functionType === "connection" ? "connections" : functionType
        }_${functionType === "connection" ? "save" : "create"}`
      ];

    // Get the total value from userAvailableFunctions based on the functionType
    let totalValue;
    if (userTeamId) {
      totalValue = memberAvailableFunctions[`total_${functionType}s`];
    } else {
      totalValue = userAvailableFunctions[`total_${functionType}s`];
    }

    // Perform the comparison and return true or false
    if (limitValue && (limitValue > totalValue || limitValue == "unlimited")) {
      return false;
    } else {
      if (purchasedPackage?.id == "1") {
        manageFreePackage();
      }
      return true;
    }
  };

  const updateCount = (functionType, actionType) => {
    if (actionType === "add") {
      if (userTeamId) {
        setMemberAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] + 1,
        }));
      } else {
        setUserAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] + 1,
        }));
      }
    } else if (actionType === "delete") {
      if (userTeamId) {
        setMemberAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] > 0
              ? prevFunctions[`total_${functionType}s`] - 1
              : 0,
        }));
      } else {
        setUserAvailableFunctions((prevFunctions) => ({
          ...prevFunctions,
          [`total_${functionType}s`]:
            prevFunctions[`total_${functionType}s`] > 0
              ? prevFunctions[`total_${functionType}s`] - 1
              : 0,
        }));
      }
    }
  };

  const manageFreePackage = (functionType) => {
    if (userAvailableFunctions?.total_cards >= 1) return functionType;
    if (userAvailableFunctions?.total_custom_urls >= 1) return functionType;
    if (userAvailableFunctions?.total_emergency_contacts >= 1)
      return functionType;
    if (userAvailableFunctions?.total_file_uploads >= 1) return functionType;
    if (userAvailableFunctions?.total_payments >= 1) return functionType;
    return false;
  };

  return { compareLimits, updateCount, userActions, manageFreePackage };
};

export default useFunctionsCount;
