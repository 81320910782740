import React from "react";
import classes from "../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";
import basicIcon from "../assets/images/settings_page_icons/basicicon.svg";
import proIcon from "../assets/images/settings_page_icons/proicon.svg";
import premiumIcon from "../assets/images/settings_page_icons/premiumicon.svg";
import { PACKAGES_LISTING, TRANSACTION } from "../utils/constants/apiUrl";
import { get } from "../server";
import {
  loggedInUser,
  subscribedPackage,
  accountType,
  isOwnerPackage,
  teamIDCheck,
  userIDCheck,
} from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import { useEffect } from "react";
import { useState } from "react";
import showToast from "../helpers/showToast";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { AiFillCheckCircle } from "react-icons/ai";
// Load Stripe Data
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const Subscription = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID, setUserID] = useAtom(userIDCheck);
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();

  // Handle Logout
  const handleLogout = () => {
    logout();
  };

  // Load singlePackage data
  const loadSubscriptions = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PACKAGES_LISTING,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data) {
        let ownerpackages = data.filter(
          (singlePackage) => singlePackage.package_type == "owner"
        );
        setSubscriptions(ownerpackages);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = async (obj) => {
    setSelectedPackage(obj);
    setShowModal(true);
  };

  const handlePersonalAccount = () => {
    let teamPackage = loggedUser?.package_details?.find(
      (obj) => obj.team_id == null
    );

    setIsOwnerPackageActive(false);
    setPurchasedPackage(teamPackage);
    setUserTeamId(null);
    setPersonalAccount(true);
    setUserID(null);
    navigate("/");
  };

  const checkExpire = () => {
    let isTeamPackage = loggedUser?.package_details.filter(
      (obj) => obj.team_id == loggedUser?.teams.id
    );

    let endDate = new Date(isTeamPackage[0]?.end_date);
    let todayDate = new Date();

    if (todayDate >= endDate) return false;
    return true;
  };
  return (
    <>
      <div className="">
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between px-3">
          {location.pathname === "/subscription" && (
            <div className={classes.skipBtnWrapper}>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}

          <h1 className={classes.heading}>Subscription Plans</h1>
          <div>
            {location.pathname === "/subscription" &&
              loggedUser?.role?.includes("team_owner") &&
              !personalAccount && (
                <div className={classes.skipBtnWrapper}>
                  <button
                    style={{ whiteSpace: "nowrap", width: "200px" }}
                    onClick={handlePersonalAccount}
                  >
                    Back to Personal Screen
                  </button>
                </div>
              )}

            {location.pathname === "/subscription" &&
              loggedUser?.role?.includes("team_owner") &&
              personalAccount && (
                <div className={classes.skipBtnWrapper}>
                  <button onClick={() => navigate("/")}>Go Back</button>
                </div>
              )}
          </div>
        </div>
        <div className={classes.mainWrapper}>
          <div
            className={classes.cardsWrapper}
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 330px))",
              justifyContent: "center",
            }}
          >
            {subscriptions.length !== 0
              ? subscriptions.map((singlePackage, index) => (
                  <div
                    className={classes.card}
                    key={`pricingCard${index}`}
                    style={{ padding: "20px 5px" }}
                  >
                    <button className={classes.subscriptionType}>
                      {singlePackage.package_name}
                    </button>
                    <div className={classes.imgWrapper}>
                      <img
                        src={
                          singlePackage.package_name === "Starter"
                            ? basicIcon
                            : singlePackage.package_name === "Premium"
                            ? proIcon
                            : premiumIcon
                        }
                        alt=""
                      />
                    </div>

                    <h3 className={classes.title}>
                      {singlePackage.id === 5
                        ? `$${singlePackage.price_monthly}/month`
                        : "Custom Price"}
                    </h3>
                    <ul className={classes.list} style={{ color: "#fff" }}>
                      {/*  Card Section */}
                      {singlePackage.limit_card_create == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Personal/Business Card</p>
                            <p>{singlePackage.limit_card_create}</p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Personal/Business Card</p>
                            <p>{singlePackage.limit_card_create}</p>
                          </div>
                        </li>
                      )}

                      {/* Connection */}
                      {singlePackage.limit_connections_save == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Connections</p>
                            <p>{singlePackage.limit_connections_save}</p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Connections</p>
                            <p>{singlePackage.limit_connections_save}</p>
                          </div>
                        </li>
                      )}

                      {/* Payments */}
                      {singlePackage.limit_payment_create == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Payments cards</p>
                            <p>{singlePackage.limit_payment_create}</p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Payments cards</p>
                            <p>{singlePackage.limit_payment_create}</p>
                          </div>
                        </li>
                      )}

                      {/* Emergency Contact */}
                      {singlePackage.limit_emergency_contact_create == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Emergency Contacts</p>
                            <p>
                              {singlePackage.limit_emergency_contact_create}
                            </p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Emergency Contacts</p>
                            <p>
                              {singlePackage.limit_emergency_contact_create}
                            </p>
                          </div>
                        </li>
                      )}

                      {/* Custom Url */}
                      {singlePackage.limit_custom_url_create == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Custom URL</p>
                            <p>{singlePackage.limit_custom_url_create}</p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Custom URL</p>
                            <p>{singlePackage.limit_custom_url_create}</p>
                          </div>
                        </li>
                      )}

                      {/* File Upload Url */}
                      {singlePackage.limit_file_upload_create == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>File Upload URL</p>
                            <p>{singlePackage.limit_file_upload_create}</p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>File Upload URL</p>
                            <p>{singlePackage.limit_file_upload_create}</p>
                          </div>
                        </li>
                      )}

                      {/*Custom Button */}
                      {singlePackage.enable_custom_buttons == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Custom Button</p>
                            <p>
                              {singlePackage.enable_custom_buttons
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Custom Button</p>
                            <p>
                              {singlePackage.enable_custom_buttons
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      )}

                      {/*Email Notification Button */}
                      {/* {singlePackage.enable_email_notifications == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "#535f79" }}
                          >
                            <p>Email Notification</p>
                            <p>
                              {singlePackage.enable_email_notifications
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Email Notification</p>
                            <p>
                              {singlePackage.enable_email_notifications
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      )} */}
                      {/*Maximum User Limit */}
                      {singlePackage.user_limit > 0 && (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Maximum User Limit</p>
                            <p>{singlePackage.user_limit}</p>
                          </div>
                        </li>
                      )}
                    </ul>
                    {singlePackage.id == 6 ? (
                      <div className={classes.btnWrapper}>
                        <Link to={`/PackageContactForm/${singlePackage.id}`}>
                          <button className={classes.btn}>Contact Us</button>
                        </Link>
                      </div>
                    ) : purchasedPackage?.id === 5 && checkExpire() ? (
                      <>
                        <div className={classes.btnWrapper}>
                          <button className={classes.btn} disabled>
                            Subscribed{" "}
                            <div
                              style={{
                                display: "inline",
                                // backgroundColor: "white",
                              }}
                            >
                              <AiFillCheckCircle />
                            </div>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classes.btnWrapper}>
                          <button
                            className={classes.btn}
                            onClick={() => handlePayment(singlePackage)}
                          >
                            Select Package
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          stripePromise={stripePromise}
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          elementsOptions={elementsOptions}
        />
      )}
    </>
  );
};

export default Subscription;
