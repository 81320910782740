import { useNavigate } from "react-router-dom";
import classes from "../assets/styles/EmptyListingBanner.module.css";
import heading from "../assets/images/home_page_images/heading.svg";
import dashedArrow from "../assets/images/home_page_images/dashedArrow.svg";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import showToast from "../helpers/showToast";
import { subscribedPackage } from "../utils/store";
import FreePackageCompleteModal from "./Modals/FreePackageCompleteModal";
import { useAtom } from "jotai";
import { useState } from "react";

const EmptyListingBanner = ({
  headLineText,
  title,
  onClick,
  link,
  showAdd = true,
  type = null,
  cardListing,
}) => {
  const { compareLimits, updateCount, manageFreePackage } = useFunctionsCount();
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);
  const navigate = useNavigate();

  const handleClick = (link) => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(functionTypes.CARD);
      if (checkFreePackage) {
        setSameListing(checkFreePackage === functionTypes.CARD || false);
        handleFreePackageModal();
        return false;
      }
    }

    const res = handleShowCustomUrlModal(type);
    if (res)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    navigate(link);
  };

  const handleShowCustomUrlModal = (type) => {
    if (type == "card") return compareLimits(functionTypes.CARD);
    return false;
  };

  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  return (
    <>
      <div className={classes.infoWrapper}>
        <div className={classes.contentWrapper}>
          <img src={heading} alt="" />
          <div>
            <h6>Tips</h6>
            <p>
              {`You haven't created any ${headLineText} yet, in order to get started click the "${title}"  button below`}
            </p>
          </div>
        </div>
      </div>

      {showAdd ? (
        <div className={classes.arrowImgWrapper}>
          <div className={classes.arrowImg}>
            <img src={dashedArrow} alt="" />
          </div>

          <div className={classes.createBtnWrapper}>
            {link ? (
              <button onClick={() => handleClick(link)}>{title}</button>
            ) : (
              // <Link to={link}>{title}</Link>
              <button onClick={onClick}>{title}</button>
            )}
          </div>
        </div>
      ) : null}
      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={cardListing}
      />
    </>
  );
};

export default EmptyListingBanner;
