import React, { useState, useEffect, useRef } from "react";
import Loader from "../components/Loader";
import { ALREADY_MEMBER } from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import useLogout from "../hooks/useLogout";
import useFunctionsCount from "../hooks/useFunctionsCount";

const PublicAlreadyMemberSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uuid");
  const team_id = searchParams.get("team_id");
  const email = searchParams.get("email");
  const user_id = searchParams.get("user_id");
  const count = useRef(0);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(true);
  const { userActions } = useFunctionsCount();

  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };

  const handlePublicAlreadyMemberSetup = async () => {
    let { status, message, data } = await post(ALREADY_MEMBER, {
      user_id,
      email,
      team_id,
      uuid,
    });
    if (status) {
      userActions(data);
      if (loggedUser?.email && loggedUser?.email === data?.email) {
        if (Object.keys(data).length > 0) setLoggedUser(data);

        navigate("/");
      } else {
        handleLogout();
        if (Object.keys(data).length > 0) setLoggedUser(data);
        navigate("/");
      }
    } else {
      showToast(status, message);
      navigate("/login");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      if (count.current !== 0) {
        if (uuid && team_id && email && user_id) {
          handlePublicAlreadyMemberSetup();
        }
      }
      count.current++;
    } else {
      if (uuid && team_id && email && user_id) {
        handlePublicAlreadyMemberSetup();
      }
    }
  }, [uuid, team_id, email, user_id]);

  return <>{loading ? <Loader /> : ""}</>;
};

export default PublicAlreadyMemberSetup;
