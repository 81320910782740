import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/ConfirmationModal.module.css";
import SecondLoader from "../SecondLoader";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { DELETE_FREE_FUNCTION } from "../../utils/constants/apiUrl";
import { deleteRecord } from "../../server";
import { loggedInUser, userRemainingFunctions } from "../../utils/store";
import showToast from "../../helpers/showToast";

const FreePackageCompleteModal = ({
  show,
  handleCloseModal,
  isSameFunctionPage,
  refreshListing,
}) => {
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
    userRemainingFunctions
  );

  const handleFunctionDelete = async () => {
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${DELETE_FREE_FUNCTION}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      handleCloseModal();
      setUserAvailableFunctions({
        ...userAvailableFunctions,
        total_cards: 0,
        total_custom_urls: 0,
        total_emergency_contacts: 0,
        total_file_uploads: 0,
        total_payments: 0,
      });
    }

    setLoading(false);
    showToast(status, message);

    if (isSameFunctionPage) {
      refreshListing();
    }
  };

  const navigateTpPlans = () => {
    navigate("/packages");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="customModal"
        size="sm"
      >
        {loading ? (
          <SecondLoader shortModal={true} />
        ) : (
          <>
            <Modal.Header>
              <div className="modalGradientCircle"></div>
              <MdClose onClick={handleCloseModal} className="modalCloseBtn" />
            </Modal.Header>

            <Modal.Body
              style={{ padding: "0" }}
              className={`d-flex justify-content-center flex-column align-items-center`}
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ width: "fit-content", lineHeight: "18px" }}
              >
                <h5>Feature is unavailable!</h5>
                <p
                  style={{
                    lineHeight: "18px",
                    textAlign: "center",
                    padding: "0px 20px",
                    marginTop: "10px",
                  }}
                >
                  In free mode you can only create one function at a time. In
                  order to create more functions you must either delete the
                  existing function or upgrade to a higher package.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                className={classes.btnWrapper}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  style={{
                    background: "#E35252",
                  }}
                  onClick={() => {
                    handleFunctionDelete();
                  }}
                  className={classes.cancelBtn}
                >
                  Delete Function
                </Button>

                <Button
                  type="button"
                  onClick={navigateTpPlans}
                  className={classes.saveBtn}
                >
                  Upgrade Now
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};
export default FreePackageCompleteModal;
