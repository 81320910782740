import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/MembersModal.module.css";
import { MdClose } from "react-icons/md";
import SecondLoader from "../SecondLoader";
import { Image } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import baseURL from "../../utils/constants/baseUrl";
import { getTeamMemberFunctionsName } from "../../utils/functions";

const MembersModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
  handleShowMemberModal,
  handleTeamMemberClick,
  handleShowActiveFunctionModal,
  handleRemoveMember,
  handleActiveFunctionQrOpen,
  handleShowRemoveActiveFunctionModal,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Team Members</span>
          <div
            className="modalGradientCircle"
            style={{ position: "absolute", top: "25px" }}
          ></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader shortModal={true} />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "30px" }}>
                {data.teamMembers.map((member, index) => {
                  return (
                    <div className="dropdown text-center" key={index}>
                      <button
                        className="btn customActionDropdown dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          className={classes.memberNodeBox}
                          onClick={() => handleTeamMemberClick(member)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-2">
                              <div className={classes.teamMemberImage}>
                                <Image
                                  src={
                                    member.team_member.user.profile_image
                                      ? `${baseURL.PUBLIC_URL}${member.team_member.user.profile_image}`
                                      : dummyImage
                                  }
                                  alt={"Team Member Image"}
                                  roundedCircle
                                />
                              </div>
                              <div>
                                <p className={classes.nodeName}>{`${
                                  member.team_member.user.first_name || ""
                                } ${
                                  member.team_member.user.last_name || ""
                                }`}</p>

                                <p className={`${classes.nodeDetail} mt-1`}>
                                  {member.team_member.user.email}
                                </p>

                                {member?.function_type && (
                                  <p className={`${classes.nodeDetail} mt-1`}>
                                    <b>Function:</b>{" "}
                                    {getTeamMemberFunctionsName(
                                      member?.function_type
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className={classes.threedotsWrapper}>
                              <BsThreeDotsVertical />
                            </div>
                          </div>
                        </div>
                      </button>
                      <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
                        <li>
                          <span
                            className="dropdown-item"
                            onClick={handleShowMemberModal}
                          >
                            Manage Team Member
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              handleShowActiveFunctionModal(member)
                            }
                          >
                            Manage Active Function
                          </span>
                        </li>
                        {member.function_type && (
                          <>
                            <li>
                              <span
                                className="dropdown-item"
                                onClick={() =>
                                  handleActiveFunctionQrOpen(member)
                                }
                              >
                                Function QR Code
                              </span>
                            </li>

                            <li>
                              <span
                                className="dropdown-item"
                                style={{ color: "#E35252" }}
                                onClick={() =>
                                  handleShowRemoveActiveFunctionModal(member.id)
                                }
                              >
                                Delete Active Function
                              </span>
                            </li>
                          </>
                        )}

                        <li>
                          <span
                            className="dropdown-item"
                            style={{ color: "#E35252" }}
                            onClick={() => handleRemoveMember(member)}
                          >
                            Remove from Node
                          </span>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default MembersModal;
