import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import classes from "../../assets/styles/Pages/Settings/Settings.module.css";
import Profile from "../../components/PageComponents/Settings/Profile";
import Password from "../../components/PageComponents/Settings/Password";
import Notification from "../../components/PageComponents/Settings/Notification";
import ImageUpload from "../../components/ImageUpload";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import { loggedInUser, subscribedPackage } from "../../utils/store";
import { useAtom } from "jotai";
import NFCs from "../../components/PageComponents/Settings/NFCs";
import TeamDetail from "../../components/PageComponents/Settings/TeamDetail";
import CancelSubscription from "../../components/PageComponents/Settings/CancelSubscription";
const Settings = () => {
  const [imagePath, setImagePath] = useState("");
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [name, setName] = useState("");
  const [key, setKey] = useState("profile");

  return (
    <>
      <div className="">
        <h6 className={classes.title}>Settings</h6>
        <div className={classes.tabsWrapper}>
          <div className={classes.imageWrapper}>
            <ImageUpload
              imageLink={true}
              imageUrl={imagePath}
              setPath={setImagePath}
              imageType="profile"
              dummyImage={dummyImage}
            />
            <div>
              <h6>{name}</h6>
            </div>
          </div>
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3 customTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="profile" title="Profile">
              <Profile
                imagePath={imagePath}
                setImagePath={setImagePath}
                name={name}
                setName={setName}
              />
            </Tab>
            {!loggedUser?.is_social ? (
              <Tab eventKey="password" title="Password">
                <Password />
              </Tab>
            ) : (
              ""
            )}
            {loggedUser?.role?.includes("team_owner") && (
              <Tab eventKey="teamDetail" title="Team Details">
                <TeamDetail />
              </Tab>
            )}
            {loggedUser?.role?.includes("team_owner") && (
              <Tab eventKey="cancelSubscription" title="Cancel Subscription">
                <CancelSubscription />
              </Tab>
            )}

            <Tab eventKey="notification" title="Notification">
              <Notification />
            </Tab>

            {loggedUser?.active_nfc && (
              <Tab eventKey="NFCs" title="Unlink Bracelet">
                <NFCs setKey={setKey} />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Settings;
