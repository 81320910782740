import { HiOutlineHome } from "react-icons/hi";
import { TbLayoutGridAdd } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { AiOutlineBook } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { FaIdCard, FaMoneyBillAlt } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { MdPriceChange } from "react-icons/md";
import { BsPlayCircle } from "react-icons/bs";
import { AiFillMessage } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { SiCashapp } from "react-icons/si";
import { HiLink } from "react-icons/hi";
import { IoLogoVenmo } from "react-icons/io5";
import { MdContactMail } from "react-icons/md";
import { MdOutlineNotifications } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { RiUploadCloudFill } from "react-icons/ri";
import { FaEnvelope } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { ImOffice } from "react-icons/im";
import { ReactComponent as UploadFilesIcon } from "../../assets/images/function_page_icons/UploadFilesIcon.svg";
import { loggedInUser } from "../../utils/store";
import { AiOutlineStar } from "react-icons/ai";

const routes = [
  {
    id: 1,
    level: 1,
    text: "Home",
    icon: <HiOutlineHome />,
    path: "/",
  },
  {
    id: 2,
    level: 1,
    text: "Connections",
    icon: <BsPerson />,
    path: "/contacts",
  },
  {
    id: 3,
    level: 1,
    text: "Function",
    icon: <TbLayoutGridAdd style={{ color: "#22A081" }} />,
    path: "/function",
  },
  {
    id: 4,
    level: 1,
    text: "Shop",
    icon: <FiShoppingCart />,
    path: "https://contactwearables.com/shop/",
  },
  {
    id: 5,
    level: 1,
    text: "Settings",
    icon: <FiSettings />,
    path: "/settings",
  },
  {
    id: 6,
    level: 1,
    text: "Packages",
    icon: <AiOutlineStar />,
    path: "/packages",
  },
  // {
  //   level: 1,
  //   text: "Pages",
  //   icon: <AiOutlineBook />,
  //   // path: "/settings/pages",
  //   path: "/pages",
  // },
  // {
  //   level: 1,
  //   text: "Users",
  //   icon: <BsPerson />,
  //   // path: "/settings/pages",
  //   path: "/users",
  // },
  // {
  //   level: 1,
  //   text: "Pricing",
  //   icon: <AiOutlineBook />,
  //   path: "/pricing",
  // },
  // {
  //   level: 1,
  //   text: "Tutorial",
  //   icon: <AiOutlineBook />,
  //   path: "/tutorial",
  // },
];

const otherRoutes = [
  {
    id: 50,
    level: 1,
    text: "Help",
    icon: <BiHelpCircle />,
    path: "/help",
  },
];

const teamOwnerRoutes = [
  // {
  //   id: 1,
  //   level: 1,
  //   text: "Location",
  //   icon: <ImLocation2 />,
  //   path: "/location",
  // },
  // {
  //   id: 2,
  //   level: 1,
  //   text: "Department",
  //   icon: <ImOffice />,
  //   path: "/department",
  // },
  {
    id: 1,
    level: 1,
    text: "Team",
    icon: <HiUserGroup />,
    path: "/team",
  },
  {
    id: 2,
    level: 1,
    text: "Invitations",
    icon: <MdOutlineEmail />,
    path: "/invitations",
  },

  {
    id: 3,
    level: 1,
    text: "Connections",
    icon: <BsPerson />,
    path: `/contacts`,
  },
  {
    id: 4,
    level: 1,
    text: "Function",
    icon: <TbLayoutGridAdd style={{ color: "#22A081" }} />,
    path: "/function",
  },
  {
    id: 5,
    level: 1,
    text: "Subscription",
    icon: <MdPriceChange />,
    path: "/viewSubscription",
  },
  {
    id: 6,
    level: 1,
    text: "Settings",
    icon: <FiSettings />,
    path: "/settings",
  },
  {
    id: 7,
    level: 1,
    text: "Pending Payments",
    icon: <FaMoneyBillAlt />,
    path: "/pendingPayments",
  },
];

const adminRoutes = [
  {
    id: 1,
    level: 1,
    text: "Function",
    icon: <TbLayoutGridAdd style={{ color: "#22A081" }} />,

    childrens: [
      {
        id: 1,
        text: "Cards",
        icon: <FaIdCard />,
        path: "/cards",
      },
      {
        id: 2,
        text: "Custom URL'S",
        icon: <HiLink />,
        path: "/customUrls",
      },
      {
        id: 3,
        text: "File Upload",
        icon: <RiUploadCloudFill />,
        path: "/fileUpload",
      },
      {
        id: 4,
        text: "Payment Services",
        icon: <SiCashapp />,
        path: "/payments",
      },
      // {
      //   id: 11,
      //   text: "Venmo",
      //   icon: <IoLogoVenmo />,
      //   path: "/venmos",
      // },
      {
        id: 5,
        text: "Emergency",
        icon: <MdContactMail />,
        path: "/emergenctContacts",
      },
    ],
  },
  {
    id: 2,
    level: 1,
    text: "Help",
    icon: <BiHelpCircle />,

    childrens: [
      {
        id: 1,
        level: 1,
        text: "Tutorial",
        icon: <BsPlayCircle />,
        path: "/tutorial",
      },
      {
        id: 2,
        level: 1,
        text: "FAQ's",
        icon: <AiFillMessage />,
        path: "/faqs",
      },
      {
        id: 3,
        level: 1,
        text: "Contact Us",
        icon: <BiPhone />,
        path: "/contactUs",
      },
    ],
  },
  {
    id: 3,
    level: 1,
    text: "Admin Users",
    icon: <HiUserGroup />,
    path: "/adminUser",
  },
  {
    id: 4,
    level: 1,
    text: "Pages",
    icon: <AiOutlineBook />,
    path: "/pages",
  },
  {
    id: 5,
    level: 1,
    text: "Users",
    icon: <BsPerson />,
    path: "/users",
  },
  {
    id: 6,
    level: 1,
    text: "Team Owners",
    icon: <HiUserGroup />,
    path: "/allTeamOwners",
  },

  {
    id: 7,
    level: 1,
    text: "Package Contacts",
    icon: <AiOutlineBook />,
    path: "/packageContacts",
  },
  {
    id: 8,
    level: 1,
    text: "Send Notification",
    icon: <MdOutlineNotifications />,
    path: "/sendNotification",
  },
  {
    id: 9,
    level: 1,
    text: "App Setting",
    icon: <FiSettings />,
    path: "/appSetting",
  },

  // {
  //   level: 1,
  //   text: "Tutorial",
  //   icon: <BsPlayCircle />,
  //   path: "/tutorial",
  // },
  // {
  //   level: 1,
  //   text: "FAQ's",
  //   icon: <AiFillMessage />,
  //   path: "/faqs",
  // },
  // {
  //   level: 1,
  //   text: "Contact Us",
  //   icon: <BiPhone />,
  //   path: "/contactUs",
  // },
];

const teamAdminRoutes = [
  {
    id: 1,
    level: 1,
    text: "Home",
    icon: <HiOutlineHome />,
    path: "/",
  },
  {
    id: 2,
    level: 1,
    text: "Team",
    icon: <HiUserGroup />,
    path: "/team",
  },
  {
    id: 3,
    level: 1,
    text: "Invitations",
    icon: <MdOutlineEmail />,
    path: "/invitations",
  },
  {
    id: 4,
    level: 1,
    text: "Connections",
    icon: <BsPerson />,
    path: `/contacts`,
  },
  {
    id: 5,
    level: 1,
    text: "Function",
    icon: <TbLayoutGridAdd style={{ color: "#22A081" }} />,
    path: "/function",
  },
  {
    id: 6,
    level: 1,
    text: "Shop",
    icon: <FiShoppingCart />,
    path: "https://contactwearables.com/shop/",
  },
  {
    id: 7,
    level: 1,
    text: "Settings",
    icon: <FiSettings />,
    path: "/settings",
  },
  {
    id: 8,
    level: 1,
    text: "Pending Payments",
    icon: <FaMoneyBillAlt />,
    path: "/pendingPayments",
  },
];

export { routes, otherRoutes, teamOwnerRoutes, adminRoutes, teamAdminRoutes };
