import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/PublicNodes.module.css";
import { useParams } from "react-router-dom";
import { get } from "../../server";
import { NODE_SCAN } from "../../utils/constants/apiUrl";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";
import QRNode from "./QRNode";
import { Image } from "react-bootstrap";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";

const PublicNodes = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [nodeData, setNodeData] = useState({ team: {}, node: [] });
  const { uuid } = useParams();

  const [selectedNode, setSelectedNode] = useState(null);
  const [parentNodes, setParentNodes] = useState([]);

  function checkHierarchyTreeIDExists(data) {
    const childrenArray = data?.children;

    return childrenArray?.some((child) =>
      child.hasOwnProperty("hierarchy_tree_id")
    );
  }

  const handleNodeClick = (node) => {
    if (node.children.length === 0 && !node.team_member) {
      showToast(
        false,
        "This node neither has any child node nor any team member"
      );
    } else {
      setParentNodes((prevNode) => [...prevNode, selectedNode]);
      setSelectedNode(node);
    }
  };

  const handleMultipleNodeClick = (type) => {
    setParentNodes((prevNode) => [...prevNode, selectedNode]);

    let childs = [];
    selectedNode.children.forEach((single) => {
      let isNode = single.hasOwnProperty("hierarchy_tree_id");

      if (type === "node" && !isNode) {
        childs.push(single);
      } else if (type === "member" && isNode) {
        childs.push(single);
      }
    });

    setSelectedNode({ name: selectedNode.name, children: childs });
  };

  const handleBackClick = () => {
    if (parentNodes.length > 0) {
      const previousParentNode = parentNodes.pop(); // Remove the last element from the parentNodes array
      setSelectedNode(previousParentNode); // Set the previous parent node as the selectedNode
      setParentNodes([...parentNodes]); // Update the parentNodePath array
    } else {
      setSelectedNode(null); // If parentNodePath is empty, set selectedNode to null (indicating the root level)
    }
  };

  // Define a recursive function to traverse the node array
  function traverseNode(node, teamMembers) {
    // Loop through each node object

    for (let i = 0; i < node?.length; i++) {
      // Get the current node object
      let currentNode = node[i];
      currentNode.hasNodes = true;

      let teamMembersCount = 0;

      // Loop through each team member object
      for (let j = 0; j < teamMembers.length; j++) {
        // Get the current team member object
        let currentTeamMember = teamMembers[j];
        // Compare the id of the node object with the hierarchy tree id of the team member object

        if (currentNode.id === currentTeamMember.hierarchy_tree_id) {
          // Push the team member object into the children key of the node object
          currentNode?.children?.push(currentTeamMember);

          if (currentNode.children.length > 0) {
            currentNode.totalSubNodes = currentNode.children.length;
            currentNode.totalTeamMembers = 0 + 1;
          }

          teamMembersCount++;
          currentNode.hasMembers = true;
        }
      }

      currentNode.teamMembersCount = teamMembersCount;
      currentNode.nodesCount =
        currentNode?.children?.length - teamMembersCount || 0;

      // Check if the node object has any children
      if (currentNode?.children?.length > 0) {
        // Call the function recursively on the children array
        traverseNode(currentNode.children, teamMembers);
      }
    }
  }

  const getPublicNodes = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${NODE_SCAN}/${uuid}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      const modifiedData = { ...data };
      modifiedData.node = [modifiedData.node];
      // Call the function on the node array and the team members array
      traverseNode(modifiedData.node, modifiedData.team_members);

      setNodeData({
        ...nodeData,
        team: modifiedData.team,
        node: modifiedData.node,
      });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPublicNodes();
  }, []);

  // const handleNodeClick = (node) => {
  //   if (node.team_member) {
  //     window.open(
  //       `/publicFunctions/${nodeData.team.id}/${node.team_member.user_id}`,
  //       "_blank"
  //     );
  //   } else {
  //     setParentNode(selectedNode);
  //     setSelectedNode(node);

  //     // Find team members whose nodeId matches the id of the clicked node
  //     const nodeMembers = nodeData?.team_members.filter(
  //       (member) => member.hierarchy_tree_id === node.id
  //     );

  //     // Update the rendering logic for nodes and team members
  //     if (nodeMembers.length > 0) {
  //       // If there are team members, render them in the last tab
  //       setSelectedNode({
  //         ...node,
  //         children: nodeMembers.map((member) => ({
  //           ...member,
  //           parent_id: node.id,
  //           main_node: 0,
  //           children: [],
  //         })),
  //       });
  //     } else {
  //       // If there are no team members, proceed with the original rendering logic
  //       setSelectedNode(node);
  //     }
  //   }
  // };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="py-5">
        <div className={classes.mainWrapper}>
          <h6 className={classes.title}>Public Node</h6>
          <div className={classes.profileCardWrapper}>
            <h6 className={classes.teamName}>{nodeData?.team?.name}</h6>
            {selectedNode ? (
              <>
                <div className={classes.backBtnWrapper}>
                  <button onClick={handleBackClick}>Back</button>
                </div>
                <h6 className={classes.companyName}>
                  {selectedNode.name} ({selectedNode.children.length})
                </h6>

                <div className={classes.childNodes}>
                  {selectedNode.hasNodes && selectedNode.hasMembers ? (
                    <>
                      <div
                        className={classes.nodeBox}
                        onClick={() => handleMultipleNodeClick("node")}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.nodeImage}>N</div>
                          <div>
                            <p className={classes.nodeName}>
                              Nodes ({selectedNode.nodesCount})
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className={classes.nodeBox}
                        onClick={() => handleMultipleNodeClick("member")}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <div className={classes.teamMemberImage}>
                            <Image
                              src={dummyImage}
                              alt={"Team Member Image"}
                              roundedCircle
                            />
                          </div>

                          <div>
                            <p className={classes.nodeName}>
                              Members ({selectedNode.teamMembersCount})
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {selectedNode.children.map((childNode) => (
                        <QRNode
                          key={childNode.id}
                          node={childNode}
                          onNodeClick={handleNodeClick}
                          nodeData={nodeData}
                          checkHierarchyTreeIDExists={
                            checkHierarchyTreeIDExists
                          }
                        />
                      ))}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {nodeData?.node?.map((node) => (
                  <QRNode
                    key={node.id}
                    node={node}
                    onNodeClick={handleNodeClick}
                    nodeData={nodeData}
                    checkHierarchyTreeIDExists={checkHierarchyTreeIDExists}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PublicNodes;
