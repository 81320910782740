import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, put, post } from "../../server";
import { ADD_TEAM_MEMBER, USER, GET_USER } from "../../utils/constants/apiUrl";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import showToast from "../../helpers/showToast";
import { useParams, useNavigate, Link } from "react-router-dom";
import SettingsInput from "../../components/Forms/SettingsInput";
import AddEditUserSchema from "../../utils/SchemasValidation/AddEditUserSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import person from "../../assets/images/settings_page_icons/person.svg";
import emailicon from "../../assets/images/settings_page_icons/email.svg";
import location from "../../assets/images/settings_page_icons/location.svg";
import passwordIcon from "../../assets/images/signup_icons/lock.svg";
import dateofbirth from "../../assets/images/settings_page_icons/dateofbirth.svg";
import facebook from "../../assets/images/settings_page_icons/facebook.svg";
import twitter from "../../assets/images/settings_page_icons/twitter.svg";
import linkdin from "../../assets/images/settings_page_icons/linkdin.svg";
import dribble from "../../assets/images/settings_page_icons/dribble.svg";
import instagram from "../../assets/images/settings_page_icons/instagram.svg";
import behance from "../../assets/images/settings_page_icons/behance.svg";
import phoneIcon from "../../assets/images/settings_page_icons/phone.svg";
import PhoneInput from "react-phone-input-2";
import jobIcon from "../../assets/images/settings_page_icons/job.svg";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validatePhoneWithCountryCode } from "../../utils/functions";
import ImageUpload from "../../components/ImageUpload";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import uploadImage from "../../helpers/uploadImage";

let emptyObj = {
  id: "",
  first_name: "",
  last_name: "",
  dob: "",
  gender: "",
  profile_image: "",
  city: "",
  country: "",
  address: "",
  phone_number: "",
  job_title: "",
  email: "",
  password: "",
  confirm_password: "",
  user_social_media: [],
};

const AddEditTeamMember = () => {
  const { userId } = useParams();

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [profile, setProfile] = useState(emptyObj);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (imagePath) {
      setProfile({ ...profile, profile_image: imagePath });
    }
  }, [imagePath]);

  // Hanlde on change
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };

  // Validate form
  const validateForm = () => {
    let profileData = profile;
    if (!userId) delete profileData.id;
    let newErrors = ValidateSchema(profileData, AddEditUserSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    if (
      profileData.phone_number.length > profileData.country_phone ||
      profileData.phone_number.length === profileData.country_phone
    ) {
      let resultPhone = validatePhoneWithCountryCode(
        profileData.country_phone,
        profileData.phone_number
      );

      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }

    return true;
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    if (!validateForm()) return;
    setLoading(true);
    profile.user_social_media.map((ele) => {
      ["updated_at", "created_at", "placeholder"].forEach((e) => delete ele[e]);
    });

    let newDob = profile.dob;
    if (profile.dob) {
      newDob = `${
        newDob.getMonth() + 1
      }/${newDob.getDate()}/${newDob.getFullYear()}`;
    }

    let payload = { ...profile };

    payload.dob = newDob;
    if (!payload.password) delete payload.password;
    if (!payload.confirm_password) delete payload.confirm_password;

    if (payload.phone_number && payload.country_phone.length > 0) {
      payload.phone_number = payload.phone_number.slice(
        payload.country_phone.length,
        payload.phone_number.length
      );
    } else {
      payload.country_phone = "";
    }

    if (!payload.phone_number && payload.country_phone) {
      payload.country_phone = "";
    }

    if (payload.profile_image && typeof payload.profile_image == "object") {
      const { status, message, data } = await uploadImage(
        "profile",
        payload.profile_image,
        loggedUser?.token
      );

      if (status) {
        payload.profile_image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    if (userId) {
      let { status, message, data } = await put(
        `${USER}/${userId}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate(-1);
      }

      showToast(status, message);
    } else {
      payload.team_id =
        Object.keys(loggedUser).length !== 0 ? loggedUser.teams.id : null;
      let { status, message, data } = await post(
        `${ADD_TEAM_MEMBER}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate(-1);
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  // load user
  const loadUser = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      USER + "/" + userId,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setImagePath(data.profile_image);

      if (data.dob) {
        data.dob = new Date(data.dob);
      }

      if (data.country_phone) {
        data.country_phone = `${data.country_phone}`;
      } else {
        data.country_phone = "";
      }

      if (data.phone_number) {
        data.phone_number = `${data.country_phone ? data.country_phone : ""}${
          data.phone_number
        }`;
      } else {
        data.phone_number = "";
      }

      data = { ...data, password: "", confirm_password: "" };

      setProfile(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleDateChange = (e) => {
    setProfile({ ...profile, dob: e });
  };

  useEffect(() => {
    if (userId) {
      loadUser();
    }
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <h6 className="pageTitle">
            <Link to="/team/teamMembers" style={{ color: "#ffffff" }}>
              Team Members
            </Link>
            {" > "}
            {userId ? "Edit" : "Add"} Team Member
          </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.imageWrapper}>
              <ImageUpload
                imageLink={true}
                imageUrl={imagePath}
                setPath={setImagePath}
                imageType="profile"
                dummyImage={dummyImage}
              />
              <div>
                <h6>{`${profile.first_name} ${profile.last_name}`}</h6>
              </div>
            </div>

            <div className={classes.profileWrapper}>
              <h6 className={classes.title}>Account Info</h6>

              <div className={classes.bioDataWrapper}>
                <div className="row gx-5 gy-3">
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="First name"
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={profile.first_name}
                      handleChange={handleChange}
                      icon={person}
                      error={errors.first_name}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Email"
                      type="text"
                      placeholder="Email"
                      socialProfiles={false}
                      name="email"
                      value={profile.email}
                      handleChange={handleChange}
                      icon={emailicon}
                      error={errors.email}
                    />
                  </div>
                  <div className="col-lg-4 col-12"></div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Last name"
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={profile.last_name}
                      handleChange={handleChange}
                      icon={person}
                      error={errors.last_name}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Live in"
                      type="text"
                      placeholder="Live in"
                      socialProfiles={false}
                      name="city"
                      value={profile.city}
                      handleChange={handleChange}
                      icon={location}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-lg-4 col-12"></div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <label htmlFor="" className={classes.label}>
                      Contact Number
                    </label>
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      countryCodeEditable={false}
                      inputClass={
                        errors.phone_number
                          ? "phoneNumberInput errorInput"
                          : "phoneNumberInput"
                      }
                      value={profile.phone_number}
                      onChange={(value, country, e, formattedValue) => {
                        setProfile({
                          ...profile,
                          phone_number: value,
                          country_phone: country.dialCode,
                        });
                      }}
                    />
                    <span className="errorText">{errors.phone_number}</span>
                  </div>

                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Country"
                      type="text"
                      placeholder="Country"
                      socialProfiles={false}
                      name="country"
                      value={profile.country}
                      handleChange={handleChange}
                      icon={location}
                      error={errors.country}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12"></div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <label htmlFor="" className={classes.label}>
                      Date of Birth
                    </label>
                    <DatePicker
                      selected={profile.dob}
                      onChange={handleDateChange}
                      dateFormat="MM/dd/yyyy"
                      className="dobInput"
                      placeholderText="MM/dd/yyyy"
                      showYearDropdown
                      isClearable
                      maxDate={new Date()}
                      yearDropdownItemNumber={110}
                      scrollableYearDropdown
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Job, Position"
                      type="text"
                      placeholder="Photographer"
                      socialProfiles={false}
                      name="job_title"
                      value={profile.job_title}
                      handleChange={handleChange}
                      icon={jobIcon}
                      error={errors.job_title}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12"></div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <div className={classes.selectWrapper}>
                      <label className={classes.label}>Gender</label>
                      <div className="input-group globalInputWithIcon">
                        <select
                          style={{ border: "0" }}
                          className="form-select customSelect"
                          name="gender"
                          value={profile.gender}
                          onChange={handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Prefer not to say</option>
                        </select>
                      </div>
                      <span className="errorText">{errors.gender}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Password"
                      type="password"
                      placeholder="********"
                      socialProfiles={false}
                      name="password"
                      value={profile.password}
                      handleChange={handleChange}
                      icon={passwordIcon}
                      error={errors.password}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 col-12"></div>
                  <div className="col-md-6 col-lg-4 col-12">
                    <SettingsInput
                      label="Confirm Password"
                      type="password"
                      placeholder="********"
                      socialProfiles={false}
                      name="confirm_password"
                      value={profile.confirm_password}
                      handleChange={handleChange}
                      icon={passwordIcon}
                      error={errors.confirm_password}
                    />
                  </div> */}
                </div>
              </div>

              <div className={classes.submitBtnWrapper}>
                <button type="button" onClick={handleSubmit}>
                  {userId ? "Update" : "Add"} Member
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddEditTeamMember;
