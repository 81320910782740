import React, { useState, useEffect } from "react";
import classes from "../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../assets/images/function_page_icons/contacticon.svg";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Loader from "../components/Loader";
import Image from "react-bootstrap/Image";
import {
  SHARE_EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_VCF_FILE_URL,
} from "../utils/constants/apiUrl";
import { get, post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import baseURL from "../utils/constants/baseUrl";
import FormInput from "../components/Forms/FormInput";
import { Link, useParams } from "react-router-dom";
import dummyImage from "../assets/images/global_icons/dummyimage.png";
import personicon from "../assets/images/global_icons/personicon.png";
import _ from "lodash";
import formatPhoneNumber from "../helpers/formatPhoneNumber";

const CreateEmergencyContact = ({ emergencyContact }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  // const [loading, setLoading] = useState(true);
  // const [emergencyContact, setEmergencyContact] = useState({});
  const [VCFData, setVCFData] = useState(emergencyContact);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText
    ? emergencyContact?.description
    : emergencyContact?.description?.length > 60
    ? emergencyContact?.description?.slice(0, 60) + "..."
    : emergencyContact?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  // const loadEmergencyContact = async () => {
  //   let { status, message, data } = await get(
  //     `${SHARE_EMERGENCY_CONTACT}/${uuid}`
  //   );

  //   setVCFData(data);
  //   if (status) {
  //     setEmergencyContact(data);
  //   } else {
  //     showToast(status, message);
  //   }

  //   setLoading(false);
  // };

  // useEffect(() => {
  //   loadEmergencyContact();
  // }, []);

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>Emergency Contact </h6>
            <div className={classes.cardWrapper}>
              <div className="">
                <div className={classes.profileCardWrapper}>
                  <div className={classes.imgWrapper}>
                    <Image
                      className="globalIMageFit"
                      src={
                        emergencyContact.image
                          ? `${baseURL.PUBLIC_URL}${emergencyContact.image}`
                          : dummyImage
                      }
                      alt="img"
                      roundedCircle={true}
                      onLoad={() => setImageLoaded(true)}
                    />
                  </div>
                  <div className={classes.detailWrapper}>
                    <h6>{`${emergencyContact.first_name || ""} ${
                      emergencyContact.last_name || ""
                    }`}</h6>

                    <p>{`+${emergencyContact.country_phone}${formatPhoneNumber(
                      emergencyContact.phone_number
                    )}`}</p>
                    <p className={classes.description}>
                      {displayText}

                      {emergencyContact?.description?.length > 60 && (
                        <button onClick={toggleText}>
                          {showFullText ? "Show Less" : "Read More"}
                        </button>
                      )}
                    </p>
                  </div>
                  <div
                    className={` text-center ${classes.savePhonebookBtnWrapper}`}
                  >
                    <a
                      href={`tel: ${`+${emergencyContact.country_phone}${emergencyContact.phone_number}`}`}
                    >
                      <button>Call Now</button>
                    </a>
                    <button onClick={handleVcfFile}>
                      <span>
                        <img src={contacticon} alt="" />
                      </span>
                      Save Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.cardFooter}>
              <div className="d-flex align-items-center gap-3 justify-content-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.holguinmedia.contactapp"
                  target="_blank"
                >
                  <img src={playstore} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/pk/app/contact-share-digital-info/id1662851237"
                  target="_blank"
                >
                  <img src={appstore} alt="" />
                </a>
              </div>
              <p>
                © {new Date().getFullYear()} Contact Wearables, All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEmergencyContact;
