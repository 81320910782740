import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Help/PrivacyAndPolicy.module.css";
import privacy_image from "../../assets/images/help/privacy.svg";
import { Link } from "react-router-dom";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";

const IntellectualProperty = () => {
  const [loading, setLoading] = useState(false);
  const [intellectualProperty, setIntellectualProperty] = useState({});
  const loadExchangePolicy = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/page");
    if (status) {
      if (data.length > 0) {
        const policy = data.filter(
          (page) => page.page_type === "intellectual_property"
        );

        setIntellectualProperty(policy["0"]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadExchangePolicy();
  }, []);
  return (
    <>
      <div className="helpBackLinkWrapper">
        <Link to="/help">Back</Link>
      </div>
      <div className={classes.helpHeader}>
        <div className="row flex-md-row flex-column-reverse gy-4">
          {/* PrivacyAndPolicy top left section */}
          <div className="col-sm-6">
            <h3 className="helpHeading">Intellectual Property</h3>
            <p className="helpSubHeading">
              Contact Wearable’s Intellectual Property
            </p>
            <p className="helpDetail">
              Contact Wearables holds valuable intellectual property rights,
              including pending patents and granted trademarks from the United
              States Patent and Trademark Office. We take pride in our
              innovations and are committed to safeguarding and enforcing our
              rights when necessary. Below are the details of our Intellectual
              Property.
            </p>
          </div>

          {/*Right Section Image */}
          <div className="col-sm-6">
            <img src={privacy_image} alt="" className="helpImage" />
          </div>
        </div>
      </div>

      {!loading ? (
        <>
          <div className={`${classes.contentWrapper}`}>
            <p className="helpSubHeading">{intellectualProperty?.title}</p>

            <p
              className={classes.detail}
              dangerouslySetInnerHTML={{
                __html: intellectualProperty?.description,
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default IntellectualProperty;
