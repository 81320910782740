import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  routes,
  otherRoutes,
  teamOwnerRoutes,
  adminRoutes,
  teamAdminRoutes,
} from "../utils/data/routes";
import {
  loggedInUser,
  teamIDCheck,
  accountType,
  subscribedPackage,
  isOwnerPackage,
  userIDCheck,
} from "../utils/store";
import logo from "../assets/images/sidebar_icons/logo.svg";
import collapsedLogo from "../assets/images/sidebar_icons/collapsedLogo.svg";
import menu from "../assets/images/sidebar_icons/menu.svg";
import classes from "../assets/styles/Sidebar.module.css";
import { useAtom } from "jotai";
import { BsPerson } from "react-icons/bs";
import { BiGroup } from "react-icons/bi";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoIosLogOut } from "react-icons/io";
import { MdAccountBox } from "react-icons/md";
import showToast from "../helpers/showToast";
import useLogout from "../hooks/useLogout";
import {
  DELETE_USER_ACCOUNT,
  DELETE_OWNER_ACCOUNT,
} from "../utils/constants/apiUrl";
import ConfirmationModal from "./Modals/ConfirmationModal";
import { deleteRecord } from "../server";

const Sidebar = ({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  handleHideSidebar,
}) => {
  const [userID, setUserID] = useAtom(userIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [showModal, setShowModal] = useState(false);
  const logout = useLogout();

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal();
    if (loggedUser.role.includes("team_owner")) {
      let { status, message } = await deleteRecord(
        DELETE_OWNER_ACCOUNT,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        showToast(status, "Account Deleted Successfully");
        logout();
        navigate("/login");
      } else {
        showToast(status, message);
      }
    } else {
      let { status, message } = await deleteRecord(
        DELETE_USER_ACCOUNT,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        showToast(status, "Account Deleted Successfully");
        logout();
        navigate("/login");
      } else {
        showToast(status, message);
      }
    }
  };

  const handleSwitchAccount = () => {
    const withTeamIdData = loggedUser?.secret_code?.filter(
      (item) => item.team_id !== null
    );

    if (personalAccount) {
      // check if two roles and include team owner
      if (loggedUser?.role?.includes("team_owner")) {
        let teamID = withTeamIdData[0]?.team_id;

        let teamPackage = loggedUser?.package_details?.find(
          (obj) => obj.team_id == teamID
        );

        if (teamPackage) {
          setPurchasedPackage(teamPackage);
        }

        setUserTeamId(teamID);
        setPersonalAccount(false);
        setUserID(loggedUser?.teams?.user_id);
        let isTeamPackage = loggedUser?.package_details.filter(
          (obj) => obj.team_id == teamID
        );

        if (isTeamPackage.length > 0) {
          let endDate = new Date(isTeamPackage[0]?.end_date);
          let startDate = new Date();
          if (isTeamPackage.length <= 0 || startDate >= endDate) {
            navigate("/paymentNotPaid");
          } else {
            setIsOwnerPackageActive(true);
            navigate("/team");
          }
        } else {
          navigate("/paymentNotPaid");
        }
      } else {
        const isSuspended = loggedUser?.team_members?.some(
          (member) => member.check_status === "suspended"
        );
        if (isSuspended) {
          showToast(
            false,
            "Your account has been suspended by admin, please contact support for further queries"
          );
        } else {
          let teamID = withTeamIdData[0]?.team_id;
          let isTeamPackage = loggedUser?.package_details.filter(
            (obj) => obj.team_id == teamID
          );

          let endDate = new Date(isTeamPackage[0]?.end_date);
          let startDate = new Date();
          if (isTeamPackage.length <= 0 || startDate >= endDate) {
            return showToast(
              false,
              "You can not switch to team mode because your team is not subscribed to any package"
            );
          }
          let teamPackage = loggedUser?.package_details?.find(
            (obj) => obj.team_id == teamID
          );
          setPurchasedPackage(teamPackage);
          setUserTeamId(withTeamIdData[0]?.team_id);
          setPersonalAccount(false);
          navigate("/");
        }
      }
    } else {
      let teamPackage = loggedUser?.package_details?.find(
        (obj) => obj.team_id == null
      );

      setIsOwnerPackageActive(false);
      setPurchasedPackage(teamPackage);
      setUserTeamId(null);
      setPersonalAccount(true);
      setUserID(null);
      navigate("/");
    }
  };

  const [openSubmenu, setOpenSubmenu] = useState({ open: false, name: "" });

  const handleClick = (level, text) => {
    if (level === 2) {
      if (openSubmenu.name === text) {
        setOpenSubmenu({ ...openSubmenu, open: !openSubmenu.open });
      } else {
        setOpenSubmenu({ open: true, name: text });
      }
    }
  };
  const navigate = useNavigate();
  let routesData = routes;
  if (loggedUser && loggedUser?.role) {
    if (["sub_admin", "super_admin"].includes(loggedUser.role[0])) {
      // for Subroutes not show
      if (loggedUser.role[0] === "sub_admin") {
        routesData = adminRoutes.filter((el) => el.path !== "/adminUser");
      } else {
        routesData = adminRoutes;
      }
    }

    if (loggedUser.role?.includes("team_admin") && !personalAccount) {
      routesData = teamAdminRoutes;
    }

    if (!personalAccount) {
      routesData = routesData.filter((el) => el.path !== "/packages");
    }

    // Handle routes for team owner
    if (loggedUser.role?.includes("team_owner") && !personalAccount) {
      routesData = teamOwnerRoutes;
    }
  }

  const handleToggleSidebar = () => {
    setIsSidebarCollapsed((preValue) => !preValue);
  };

  const handleLogout = () => {
    logout();
  };

  const styles = {
    list: {
      margin: "85px 15px",
    },

    mainSubitem: {
      display: "flex",
      alignItems: "center",
      justifyContent: isSidebarCollapsed ? "center" : "space-between",
      padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
      borderRadius: isSidebarCollapsed ? "10px" : "36px",
      color: "#535F79",
      gap: "10px",
      height: "40px",
      whiteSpace: "nowrap",
    },
  };

  return (
    <>
      <aside
        className={isSidebarCollapsed ? "collapsedSidebar" : "sidebar"}
        style={{
          height:
            isSidebarCollapsed &&
            loggedUser.role[0] === "super_admin" &&
            "100%",
        }}
      >
        <ul className={classes.toggleBtnWrapper}>
          <li>
            <button className={classes.toggleBtn}>
              {isSidebarCollapsed ? (
                <>
                  <img
                    src={collapsedLogo}
                    alt="collapsedLogo"
                    onClick={handleToggleSidebar}
                    className={classes.logoBtn}
                  />
                  <img
                    src={collapsedLogo}
                    alt="collapsedLogo"
                    className={classes.nonClickAbleLogoBtn}
                  />
                </>
              ) : (
                <img src={logo} alt="logo" />
              )}
            </button>
          </li>
          <li className={isSidebarCollapsed ? classes.toHideBtn : ""}>
            <button className={classes.menuBtn} onClick={handleToggleSidebar}>
              <img src={menu} alt="menu" />
            </button>
          </li>
        </ul>
        <ul className={classes.list}>
          {routesData.map((route) => {
            if (route.childrens) {
              return (
                <li
                  className={classes.mainSubItem}
                  key={`${route.id}-${route.text}`}
                  in={openSubmenu.open && openSubmenu.name === route.text}
                >
                  <NavLink
                    to={route.path}
                    style={styles.mainSubitem}
                    onClick={() => handleClick(2, route.text)}
                  >
                    <div className={classes.listItemContentWrapper}>
                      <span>{route.icon}</span>

                      <p
                        style={{
                          display: isSidebarCollapsed ? "none" : "block",
                        }}
                        className={classes.listItemText}
                      >
                        {route.text}
                      </p>
                    </div>
                    {isSidebarCollapsed ? null : (
                      <RiArrowDownSLine
                        style={{ transition: "all 0.3s ease" }}
                        className={
                          openSubmenu.open && openSubmenu.name === route.text
                            ? classes.rotateSubMenuIcon
                            : null
                        }
                      />
                    )}
                  </NavLink>
                  {isSidebarCollapsed ? (
                    <ul className={classes.adminPanelCollapsedSubmenu}>
                      {route.childrens.map((child) => {
                        return (
                          <li
                            className={classes.listItem}
                            key={`${child.id}=${child.text}`}
                          >
                            <NavLink
                              to={child.path}
                              style={{
                                justifyContent: "flex-start",
                                padding: "0 15px",
                                borderRadius: "36px",
                              }}
                              className={({ isActive }) =>
                                isActive ? classes.activeItem : classes.item
                              }
                            >
                              <span>{child.icon}</span>

                              <p className={classes.listItemText}>
                                {child.text}
                              </p>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div
                      className={
                        openSubmenu.open && openSubmenu.name === route.text
                          ? classes.showSbmenuContent
                          : classes.hideSbmenuContent
                      }
                    >
                      {route.childrens.map((child) => {
                        return (
                          <li
                            className={classes.subMenuListItem}
                            key={`${child.id}-${child.text}`}
                          >
                            <NavLink
                              onClick={handleHideSidebar}
                              to={child.path}
                              style={{
                                justifyContent: isSidebarCollapsed
                                  ? "center"
                                  : "flex-start",
                                padding: isSidebarCollapsed
                                  ? "22px 20px"
                                  : "0 15px",
                                borderRadius: isSidebarCollapsed
                                  ? "10px"
                                  : "36px",
                              }}
                              className={({ isActive }) =>
                                isActive ? classes.activeItem : classes.item
                              }
                            >
                              <span>{child.icon}</span>

                              <p
                                style={{
                                  display: isSidebarCollapsed
                                    ? "none"
                                    : "block",
                                }}
                                className={classes.listItemText}
                              >
                                {child.text}
                              </p>
                            </NavLink>
                          </li>
                        );
                      })}
                    </div>
                  )}
                </li>
              );
            } else {
              return (
                <li
                  className={classes.listItem}
                  key={`${route.id}-${route.text}`}
                >
                  {route.text === "Shop" ? (
                    <a
                      href={route.path}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.item}
                      onClick={handleHideSidebar}
                      style={{
                        justifyContent: isSidebarCollapsed
                          ? "center"
                          : "flex-start",
                        padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                        borderRadius: isSidebarCollapsed ? "10px" : "36px",
                      }}
                    >
                      <span>{route.icon}</span>

                      <p
                        style={{
                          display: isSidebarCollapsed ? "none" : "block",
                        }}
                        className={classes.listItemText}
                      >
                        {route.text}
                      </p>
                    </a>
                  ) : (
                    <NavLink
                      to={route.path}
                      onClick={handleHideSidebar}
                      style={{
                        justifyContent: isSidebarCollapsed
                          ? "center"
                          : "flex-start",
                        padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                        borderRadius: isSidebarCollapsed ? "10px" : "36px",
                      }}
                      className={({ isActive }) =>
                        isActive ? classes.activeItem : classes.item
                      }
                    >
                      <span>{route.icon}</span>

                      <p
                        style={{
                          display: isSidebarCollapsed ? "none" : "block",
                        }}
                        className={classes.listItemText}
                      >
                        {route.text}
                      </p>
                    </NavLink>
                  )}
                </li>
              );
            }
          })}
        </ul>

        {(loggedUser?.role?.includes("customer") ||
          loggedUser?.role?.includes("team_owner")) && (
          <ul className={classes.helpList}>
            {otherRoutes.map((route) => {
              return (
                <li
                  className={classes.listItem}
                  key={`${route.id} = ${route.text}`}
                >
                  <NavLink
                    onClick={handleHideSidebar}
                    to={route.path}
                    style={{
                      justifyContent: isSidebarCollapsed
                        ? "center"
                        : "flex-start",
                      padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                      borderRadius: isSidebarCollapsed ? "10px" : "36px",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.activeItem : classes.item
                    }
                  >
                    <span>{route.icon}</span>

                    <p
                      style={{
                        display: isSidebarCollapsed ? "none" : "block",
                      }}
                      className={classes.listItemText}
                    >
                      {route.text}
                    </p>
                  </NavLink>
                </li>
              );
            })}
            <li className={classes.listItem} onClick={handleShowModal}>
              <Link
                style={{
                  justifyContent: isSidebarCollapsed ? "center" : "flex-start",
                  padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                  borderRadius: isSidebarCollapsed ? "10px" : "36px",
                }}
                className={classes.item}
              >
                <span>
                  <MdAccountBox />
                </span>

                <p
                  style={{
                    display: isSidebarCollapsed ? "none" : "block",
                  }}
                  className={classes.listItemText}
                >
                  Delete Account
                </p>
              </Link>
            </li>
          </ul>
        )}
        <ul className={classes.logOutList}>
          {loggedUser?.secret_code?.length === 2 && (
            <li className={classes.listItem} onClick={handleSwitchAccount}>
              <Link
                style={{
                  justifyContent: isSidebarCollapsed ? "center" : "flex-start",
                  padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                  borderRadius: isSidebarCollapsed ? "10px" : "36px",
                }}
                className={classes.item}
              >
                <span>{personalAccount ? <BiGroup /> : <BsPerson />}</span>

                <p
                  style={{
                    display: isSidebarCollapsed ? "none" : "block",
                  }}
                  className={classes.listItemText}
                >
                  Switch to
                  {personalAccount ? " Team" : " Personal"}
                </p>
              </Link>
            </li>
          )}

          <li className={classes.listItem} onClick={() => handleLogout()}>
            <Link
              style={{
                justifyContent: isSidebarCollapsed ? "center" : "flex-start",
                padding: isSidebarCollapsed ? "22px 20px" : "0 15px",
                borderRadius: isSidebarCollapsed ? "10px" : "36px",
              }}
              className={classes.item}
            >
              <span>
                <IoIosLogOut style={{ color: "#E35252" }} />
              </span>

              <p
                style={{
                  display: isSidebarCollapsed ? "none" : "block",
                  color: "#E35252",
                }}
                className={classes.listItemText}
              >
                Log out
              </p>
            </Link>
          </li>
        </ul>
      </aside>
      {showModal && (
        <ConfirmationModal
          show={showModal}
          deleteAccountMessage={
            loggedUser?.role?.includes("team_owner") ||
            loggedUser?.role?.includes("team_admin") ||
            (loggedUser?.role?.includes("customer") &&
              loggedUser?.secret_code?.length > 1)
              ? "By deleting your account, all your personal, team related data and team subscription will be lost and it can't be recovered."
              : "By deleting your account, all your data will be lost and it can't be recovered."
          }
          confirmTitle="account"
          handleCloseModal={handleCloseModal}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Sidebar;
