import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowPaymentFunctions from "../../components/PageComponents/Function/ShowPaymentFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import { PAYMENT } from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import CashAppAddEditModal from "../../components/Modals/CashAppAddEditModal";
import PaymentAddEditModal from "../../components/Modals/PaymentAddEditModal";
import addcontact from "../../assets/images/home_page_images/addcontact.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import { formatUrl } from "../../utils/functions/index";
import _ from "lodash";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import FreePackageCompleteModal from "../../components/Modals/FreePackageCompleteModal";

const emptyPaymentData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
  payment_type: "",
};

const PaymentListing = () => {
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(true);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [cashApps, setCashApps] = useState([]);
  const [payments, setPayments] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyPaymentData);
  const [filterText, setFilterText] = useState("");
  const { compareLimits, updateCount, manageFreePackage } = useFunctionsCount();

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleShowPaymentModal = () => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(functionTypes.PAYMENT);
      if (checkFreePackage) {
        setSameListing(checkFreePackage === functionTypes.PAYMENT || false);
        handleFreePackageModal();
        return false;
      }
    } else {
      const isLimitExceeded = compareLimits(functionTypes.PAYMENT);
      if (isLimitExceeded)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
    }
    setShowModal(true);
  };

  const handleSaveShowModal = async () => {
    setLoading(true);
    let payload = _.cloneDeep(selectedData);
    payload.url_path = formatUrl(payload.url_path);
    let result = {};

    if (selectedData.id) {
      result = await put(
        PAYMENT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let paymentArr = _.cloneDeep(payments);
      if (!selectedData.id) {
        updateCount(functionTypes.PAYMENT, functionTypes.ADD);
      }
      // On Update
      if (selectedData.id) {
        const index = payments.findIndex((el) => el.id == selectedData.id);
        paymentArr[index] = data;
      } else {
        paymentArr.unshift(data);
      }

      setPayments(paymentArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyPaymentData);
    setShowModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }
    let { status, message } = await deleteRecord(
      `${PAYMENT}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      updateCount(functionTypes.PAYMENT, functionTypes.DELETE);
      await getPayments();
      setLoading(false);
    }
    setLoading(false);
  };
  // Get all Payment urls
  const getPayments = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${PAYMENT}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setPayments(data);
    } else {
      toast.error(message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = payments.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.url_path || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, payments]);
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link onClick={handleGoBack} style={{ color: "#ffffff" }}>
                Functions
              </Link>
              {` > Payment Service`}
            </h6>

            {payments.length !== 0 ? (
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            ) : null}
          </div>

          {payments.length === 0 ? (
            <EmptyListingBanner
              headLineText="payment links"
              title="Add Payment Link"
              onClick={handleShowPaymentModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowPaymentModal}>
                      <div className={classes.addContentWrapper}>
                        <img src={addcontact} alt="" />
                        <p>Add Payment Link</p>
                      </div>
                    </span>
                  ) : null}
                </div>
                <ShowPaymentFunctions
                  loading={loading}
                  show={showModal}
                  payments={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          )}

          {/* Cash app modals */}
          {showModal && (
            <PaymentAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="payment service"
      />

      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={getPayments}
      />
    </>
  );
};

export default PaymentListing;
