const Joi = require("joi");

const UserSignupSchema = Joi.object({
  id: Joi.allow(null),

  first_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `First name must be minimum 1 characters!`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.min": `Last name must be minimum 1 characters!`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Confirm password must match password`,
    }),
});
const TeamOwnerSignupSchema = Joi.object({
  id: Joi.allow(null),

  team_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter team name`,
    "string.min": `Team name must be minimum 1 characters!`,
    "string.max": `Team name must be maximum 25 characters!`,
  }),

  first_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `First name must be minimum 1 characters!`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.min": `Last name must be minimum 1 characters!`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("password"))
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Confirm password must match password`,
    }),
});
const SocialTeamOwnerSignupSchema = Joi.object({
  id: Joi.allow(null),

  team_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter team name`,
    "string.min": `Team name must be minimum 1 characters!`,
    "string.max": `Team name must be maximum 25 characters!`,
  }),

  first_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `First name must be minimum 1 characters!`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.min": `Last name must be minimum 1 characters!`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
});
const SocialUserSignupSchema = Joi.object({
  id: Joi.allow(null),

  first_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter first name`,
    "string.min": `First name must be minimum 1 characters!`,
    "string.max": `First name must be maximum 25 characters!`,
  }),

  last_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter last name`,
    "string.min": `Last name must be minimum 1 characters!`,
    "string.max": `Last name must be maximum 25 characters!`,
  }),
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
});
const UserToTeamOwnerSignupSchema = Joi.object({
  id: Joi.allow(null),

  team_name: Joi.string().min(1).max(25).required().messages({
    "string.empty": `Please enter team name`,
    "string.min": `Team name must be minimum 1 characters!`,
    "string.max": `Team name must be maximum 25 characters!`,
  }),
});
export {
  UserSignupSchema,
  TeamOwnerSignupSchema,
  SocialTeamOwnerSignupSchema,
  SocialUserSignupSchema,
  UserToTeamOwnerSignupSchema,
};
