import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedInUser } from "../utils/store";
const Missing = ({ message }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  return (
    <div className="notfoundMainWrapper">
      <div className="notfound">
        <div className="notfoundBg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        {message ? null : <h1>oops!</h1>}
        <h2>{message ? message : "Error 404 : Page Not Found"}</h2>

        {message ? null : loggedUser?.role?.includes("team_owner") ? (
          <Link to="/team">Go Back Homepage</Link>
        ) : (
          <Link to="/">Go Back Homepage</Link>
        )}
      </div>
    </div>
  );
};

export default Missing;
