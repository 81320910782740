import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";

export const locationAtom = atomWithStorage("currentLocation", {
  lat: "31.4804642",
  lng: "74.3239342",
});
export const loggedInUser = atomWithStorage("loggedUser", {});
export const mobileViewCheck = atomWithStorage("mobileView", false);
export const teamIDCheck = atomWithStorage("userTeamId", null);
export const userIDCheck = atomWithStorage("userID", null);

export const accountType = atomWithStorage("personalAccount", true);

export const subscriptionPackages = atomWithStorage("purchasePackages", []);
export const subscribedPackage = atomWithStorage("purchasedPackage", {});

export const isOwnerPackage = atomWithStorage("isOwnerPackageActive", false);
export const isPersonalPackage = atomWithStorage(
  "isPersonalPackageActive",
  false
);
export const userRemainingFunctions = atomWithStorage(
  "userAvailableFunctions",
  {
    total_cards: 0,
    total_connections: 0,
    total_payments: 0,
    total_emergency_contacts: 0,
    total_custom_urls: 0,
    total_file_uploads: 0,
  }
);
export const memberRemainingFunctions = atomWithStorage(
  "memberAvailableFunctions",
  {
    total_cards: 0,
    total_connections: 0,
    total_payments: 0,
    total_emergency_contacts: 0,
    total_custom_urls: 0,
    total_file_uploads: 0,
  }
);

export const globalAppSetting = atomWithStorage("appSetting", {});
export const globalLoading = atom(false);
