import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Profile.module.css";
import SettingsInput from "../../Forms/SettingsInput";
import Loader from "../../Loader";
import Button from "react-bootstrap/Button";
import { GET_USER, SETTING } from "../../../utils/constants/apiUrl";
import { get, post } from "../../../server";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import showToast from "../../../helpers/showToast";
import person from "../../../assets/images/settings_page_icons/person.svg";
import emailicon from "../../../assets/images/settings_page_icons/email.svg";
import phoneIcon from "../../../assets/images/settings_page_icons/phone.svg";
import location from "../../../assets/images/settings_page_icons/location.svg";
import dateofbirth from "../../../assets/images/settings_page_icons/dateofbirth.svg";
import jobIcon from "../../../assets/images/settings_page_icons/job.svg";
import gender from "../../../assets/images/settings_page_icons/gender.svg";
import ValidateSchema from "../../../helpers/ValidateSchema";
import ProfileSchema from "../../../utils/SchemasValidation/ProfileSchema";
import facebook from "../../../assets/images/settings_page_icons/facebook.svg";
import twitter from "../../../assets/images/settings_page_icons/twitter.svg";
import linkdin from "../../../assets/images/settings_page_icons/linkdin.svg";
import dribble from "../../../assets/images/settings_page_icons/dribble.svg";
import instagram from "../../../assets/images/settings_page_icons/instagram.svg";
import behance from "../../../assets/images/settings_page_icons/behance.svg";
import Select from "../../Select";
import _, { result } from "lodash";
import PhoneInput from "react-phone-input-2";
import uploadImage from "../../../helpers/uploadImage";
import handlePhoneNumber from "../../../helpers/handlePhoneNumber";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validatePhoneWithCountryCode } from "../../../utils/functions";

const Profile = ({ imagePath, setImagePath, name, setName }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [profile, setProfile] = useState({
    id: "",
    name: name,
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    profile_image: imagePath,
    country_phone: "",
    phone_number: "",
    city: "",
    country: "",
    state: "",
    address: "",
    job_title: "",
    user_social_media: [
      { media_type: "facebook", url: "", placeholder: "facebook.com/" },
      { media_type: "twitter", url: "", placeholder: "twitter.com/" },
      { media_type: "linkdin", url: "", placeholder: "linkedin.com/" },
      { media_type: "dribble", url: "", placeholder: "dribbble.com/" },
      { media_type: "instagram", url: "", placeholder: "instagram.com/" },
      { media_type: "behance", url: "", placeholder: "behance.net/" },
    ],
  });

  const [defaultMedias, setDefaultMedias] = useState([]);

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };

  const loadProfile = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setName(data.name);
      setImagePath(data.profile_image);

      if (data.dob) {
        data.dob = new Date(data.dob);
      }

      if (!data.country_phone) data.country_phone = "";

      if (data.phone_number) {
        data.phone_number = `${data.country_phone}${data.phone_number}`;
      } else {
        // for handling null
        data.phone_number = "";
      }

      if (
        loggedUser.name != data.name ||
        loggedUser.email != data.email ||
        data.profile_image != loggedUser.profile_image ||
        data.active_nfc != loggedUser.active_nfc
      ) {
        let userData = { ...loggedUser };
        userData.name = data.name;
        userData.email = data.email;
        userData.first_name = data.first_name;
        userData.last_name = data.last_name;
        userData.profile_image = data.profile_image;
        userData.active_nfc = data.active_nfc;
        setLoggedUser(userData);
      }
      setProfile(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const validateForm = () => {
    let newErrors = ValidateSchema(profile, ProfileSchema);

    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    if (profile.phone_number.length > profile.country_phone) {
      let resultPhone = validatePhoneWithCountryCode(
        profile.country_phone,
        profile.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }
    return true;
  };

  const handleDateChange = (e) => {
    setProfile({ ...profile, dob: e });
  };

  const handleSubmit = async (e) => {
    if (!validateForm()) return;
    setLoading(true);

    profile.user_social_media.map((ele) => {
      ["updated_at", "created_at", "placeholder"].forEach((e) => delete ele[e]);
    });

    let newDob = profile.dob;
    if (profile.dob) {
      newDob = `${
        newDob.getMonth() + 1
      }/${newDob.getDate()}/${newDob.getFullYear()}`;
    }

    let payload = { ...profile };

    payload.profile_image = imagePath;
    payload.dob = newDob;

    if (payload.phone_number && payload.country_phone.length > 0) {
      payload.phone_number = payload.phone_number.slice(
        payload.country_phone.length,
        payload.phone_number.length
      );
    } else {
      payload.country_phone = "";
    }

    if (payload.profile_image && typeof payload.profile_image == "object") {
      const { status, message, data } = await uploadImage(
        "profile",
        payload.profile_image,
        loggedUser?.token
      );

      if (status) {
        payload.profile_image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let { status, message, data } = await post(
      `${SETTING}/profile`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (
      loggedUser.name != data.name ||
      loggedUser.email != data.email ||
      data.profile_image != loggedUser.profile_image
    ) {
      let userData = { ...loggedUser };
      userData.name = data.name;
      userData.email = data.email;
      userData.first_name = data.first_name;
      userData.last_name = data.last_name;
      userData.profile_image = data.profile_image;
      setLoggedUser(userData);
    }

    setName(data.name);

    showToast(status, message);

    setLoading(false);
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <>
      {!loading ? (
        <div className={classes.profileWrapper}>
          <h6 className={classes.title}>Account Info</h6>
          <div className={classes.bioDataWrapper}>
            <div className="row gx-5 gy-3">
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="First name"
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={profile.first_name}
                  handleChange={handleChange}
                  icon={person}
                  error={errors.first_name}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="Email"
                  type="text"
                  placeholder="Email"
                  socialProfiles={false}
                  name="email"
                  value={profile.email}
                  handleChange={handleChange}
                  icon={emailicon}
                  error={errors.email}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12 d-md-none d-lg-block"></div>
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="Last name"
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={profile.last_name}
                  handleChange={handleChange}
                  icon={person}
                  error={errors.last_name}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12 ">
                <label htmlFor="" className={classes.label}>
                  Contact Number
                </label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputClass={
                    errors.phone_number
                      ? "phoneNumberInput errorInput"
                      : "phoneNumberInput"
                  }
                  value={profile.phone_number}
                  onChange={(value, country, e, formattedValue) => {
                    setProfile({
                      ...profile,
                      phone_number: value,
                      country_phone: country.dialCode,
                    });
                  }}
                />
                <span className="errorText">{errors.phone_number}</span>
              </div>
              <div className="col-md-6 col-lg-4 col-12 d-md-none d-lg-block "></div>
              <div className="col-md-6 col-lg-4 col-12 mt-0">
                <SettingsInput
                  label="Address"
                  type="text"
                  placeholder="Address"
                  socialProfiles={false}
                  name="address"
                  value={profile.address}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.address}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12 mt-0">
                <SettingsInput
                  label="City"
                  type="text"
                  placeholder="City"
                  socialProfiles={false}
                  name="city"
                  value={profile.city}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.city}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12 d-md-none d-lg-block"></div>
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="State"
                  type="text"
                  placeholder="State"
                  socialProfiles={false}
                  name="state"
                  value={profile.state}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.state}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="Country"
                  type="text"
                  placeholder="Country"
                  socialProfiles={false}
                  name="country"
                  value={profile.country}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.country}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12 d-md-none d-lg-block"></div>
              <div className="col-md-6 col-lg-4 col-12">
                <label htmlFor="" className={classes.label}>
                  Date of Birth
                </label>
                <DatePicker
                  selected={profile.dob}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  className="dobInput profile"
                  placeholderText="MM/dd/yyyy"
                  showYearDropdown
                  isClearable
                  maxDate={new Date()}
                  yearDropdownItemNumber={110}
                  scrollableYearDropdown
                />
              </div>
              <div className="col-md-6 col-lg-4 col-12">
                <div className={classes.selectWrapper}>
                  <label htmlFor="">Gender</label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      style={{ border: "0" }}
                      className="form-select customSelect"
                      name="gender"
                      value={profile.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Prefer not to say</option>
                    </select>
                  </div>
                  <span className="errorText">{errors.gender}</span>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-12 d-md-none d-lg-block"></div>
              <div className="col-md-6 col-lg-4 col-12 mt-0">
                <SettingsInput
                  label="Job, Position"
                  type="text"
                  placeholder="Photographer"
                  socialProfiles={false}
                  name="job_title"
                  value={profile.job_title}
                  handleChange={handleChange}
                  icon={jobIcon}
                  error={errors.job_title}
                />
              </div>
            </div>
          </div>
          <div className={classes.submitBtnWrapper}>
            <button type="button" onClick={handleSubmit}>
              Update Profile
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Profile;
