import React, { useState, useRef } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Home/PersonalCard.module.css";
import { Image, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import qrcode from "../../../assets/images/function_page_icons/qrcode.svg";
import baseUrl from "../../../utils/constants/baseUrl";
import bgImage from "../../../assets/images/home_page_images/bgImage1.png";
import { Link } from "react-router-dom";
import noImage from "../../../assets/images/no_image.jpg";
import CardView from "../Function/Card";
import { AddMoreCustomUrls } from "../../../utils/data/CardFunctionsData";
import { MdClose } from "react-icons/md";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import { get } from "../../../server";
import {
  loggedInUser,
  subscribedPackage,
  mobileViewCheck,
} from "../../../utils/store";
import { DUPLICATE_Card_URL } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import _ from "lodash";
import pictureicon from "../../../assets/images/function_page_icons/pictureicon.svg";
import companylogo from "../../../assets/images/function_page_icons/companylogo.svg";
import { MdContentCopy } from "react-icons/md";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import qrCodeBorder from "../../../assets/images/qr-code-border.svg";
import { useAtom } from "jotai";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import useFunctionsCount from "../../../hooks/useFunctionsCount";
import functionTypes from "../../../utils/constants/functionTypes";
import FreePackageCompleteModal from "../../Modals/FreePackageCompleteModal";

export default function PersonalCard({
  id,
  handleDelete,
  card_type,
  first_name,
  last_name,
  email,
  company,
  company_position,
  logo,
  background_image,
  profile_image,
  color,
  country_phone,
  phone_number,
  description,
  address,
  country,
  city,
  state,
  website_url,
  uuid,
  cardSocialMedia,
  cardListing,
  setCardListing,
  pronouns,
  cardPhone,
  teamId,
  userId,
  team_id,
  user_id,
  publicMode,
  loadCard,
}) {
  let name = `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;

  const [open, setOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [showFreePackageModal, setFreePackageModal] = useState(false);
  const [sameListing, setSameListing] = useState(false);
  const { compareLimits, updateCount, manageFreePackage } = useFunctionsCount();

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const target = useRef(null);

  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    id: id,
    card_type: card_type,
    first_name: first_name,
    last_name: last_name,
    email: email,
    company: company,
    company_position: company_position,
    logo: logo,
    background_image: background_image,
    profile_image: profile_image,
    color: color,
    country_phone: country_phone,
    phone_number: phone_number,
    description: description,
    address: address,
    country: country,
    city: city,
    state: state,
    website_url: website_url,
    uuid: uuid,
    card_social_media: cardSocialMedia,
    pronouns: pronouns,
    card_phone: cardPhone,
  });

  const handleOpen = () => {
    let newCardSocialMedia = [];

    data.card_social_media.forEach((item) => {
      let foundIcon = AddMoreCustomUrls.find(
        (single) => single.name === item.media_type
      );

      newCardSocialMedia.push({
        media_type: item.media_type,
        url: item.url,
        icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
        custom_label: item.custom_label,
      });
    });

    setData({ ...data, card_social_media: newCardSocialMedia });

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShow(false);
  };

  const handleDuplicateOpen = () => {
    if (purchasedPackage?.id == "1") {
      const checkFreePackage = manageFreePackage(functionTypes.CARD);
      if (checkFreePackage) {
        setSameListing(checkFreePackage === functionTypes.CARD || false);
        handleFreePackageModal();
        return false;
      }
    } else {
      const isLimitExceeded = compareLimits(functionTypes.CARD);
      if (isLimitExceeded)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
    }
    setDuplicateOpen(true);
  };

  const handleDuplicateClose = () => {
    setDuplicateOpen(false);
  };

  const handleShareOpen = () => {
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShow(false);
  };
  // Handle Free Package modal
  const handleFreePackageModal = () => setFreePackageModal(true);
  const handleCloseFreePackageModal = () => setFreePackageModal(false);

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleDuplicate = async () => {
    const { status, message, data } = await get(
      `${DUPLICATE_Card_URL}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      updateCount(functionTypes.CARD, functionTypes.ADD);
      let newCards = _.cloneDeep(cardListing);
      newCards.unshift(data);
      setCardListing(newCards);

      handleDuplicateClose();
    }

    showToast(status, message);
  };

  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.threeDotsDiv}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDots className={classes.threeDots} />
          </button>
          {publicMode ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
              <li>
                <span className="dropdown-item" onClick={handleOpen}>
                  View
                </span>
              </li>

              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
              <li>
                <span className="dropdown-item" onClick={handleOpen}>
                  View
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleDuplicateOpen}>
                  Duplicate
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to={
                    teamId && userId
                      ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions/${id}`
                      : `/function/cardfunctions/${id}${
                          team_id && user_id
                            ? `?team_id=${team_id}&user_id=${user_id}`
                            : ""
                        }`
                  }
                >
                  Edit
                </Link>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleShareOpen}>
                  Share
                </span>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  to="#"
                  style={{ color: "#E35252", cursor: "pointer" }}
                  onClick={() => handleDelete(id, "personal")}
                >
                  Delete
                </a>
              </li>
            </ul>
          )}
        </div>

        <div className={classes.imgWrapper}>
          <div className={classes.coverImg}>
            <Image
              src={
                background_image
                  ? baseUrl.PUBLIC_URL + background_image
                  : bgImage
              }
              alt="cover-img"
            />
          </div>

          <div className={classes.profileImg}>
            <Image
              src={
                profile_image ? baseUrl.PUBLIC_URL + profile_image : pictureicon
              }
              style={profile_image ? null : { border: "none" }}
              alt="profile-img"
              loading="lazy"
              decoding="async"
              onError={(e) => {
                e.currentTarget.src = pictureicon;
              }}
            />
          </div>
        </div>

        <div className={classes.infoWrapper}>
          <div className={`col-8 ${classes.leftSide}`}>
            <div className={classes.name}>{name}</div>
          </div>

          <img
            src={qrcode}
            alt=""
            className={classes.qrCode}
            onClick={handleQrOpen}
          />
        </div>
      </div>

      <Modal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        // className={`customModal`}
        dialogClassName="rightAlignedModal"
      >
        <CardView
          data={data}
          showSidebar={true}
          handleClose={handleClose}
          handleQrOpen={handleQrOpen}
          handleShareOpen={handleShareOpen}
          shareOpen={shareOpen}
          showCountryCodeSeparate={true}
          inViewMode={true}
          publicMode={publicMode}
        />
      </Modal>

      <Modal
        show={duplicateOpen}
        onHide={handleDuplicateClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleDuplicateClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content" }}
          >
            <h5>Are you sure?</h5>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to
              <br />
              duplicate this “Card”?
            </p>
          </div>

          <div
            className={`customModalBtnWrapper ${classes.btnWrapper}`}
            style={{ marginTop: "80px" }}
          >
            <button onClick={handleDuplicate}>Duplicate</button>
          </div>
        </Modal.Body>
      </Modal>

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          functionType="card"
          functionUuid={uuid}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShow}
          handleDownload={handleDownload}
        />
      )}
      {shareOpen && (
        <ShareModal
          shareOpen={shareOpen}
          target={target}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShow}
          functionType="card"
          functionUuid={uuid}
        />
      )}

      <FreePackageCompleteModal
        show={showFreePackageModal}
        handleCloseModal={handleCloseFreePackageModal}
        isSameFunctionPage={sameListing}
        refreshListing={loadCard}
      />

      <Overlay
        target={target.current}
        show={show}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}
