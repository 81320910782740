import React, { useState, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import classes from "../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";

import showToast from "../helpers/showToast";
import { post, get } from "../server";
import {
  loggedInUser,
  isOwnerPackage,
  subscribedPackage,
  accountType,
} from "../utils/store";
import { TRANSACTION, GET_USER } from "../utils/constants/apiUrl";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import useFunctionsCount from "../hooks/useFunctionsCount";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#fff",
      fontSize: "14px",
      color: "#fff",

      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#ff0000",
    },
  },
};

const CheckoutForm = ({ handleCloseModal, selectedPackage }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const checkbox = useRef();
  const { userActions } = useFunctionsCount();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error: stripeError, token } = await stripe.createToken(
        cardElement
      );

      if (stripeError) {
        setIsLoading(false);
        showToast(false, stripeError.message);
        return;
      }

      let paymentObj = {
        stripe_token: token.id,
        auto_renew: checkbox.current.checked ? true : false,
        package_id: selectedPackage?.id,
      };

      // Send payment method ID and payment intent id to your backend serve
      let { status, message } = await post(
        `${TRANSACTION}/processPayment`,
        paymentObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (!status) {
        setIsLoading(false);
        showToast(false, message);
        return;
      }

      let paymentMessage = message;
      if (status) {
        let { status, message, data } = await get(
          GET_USER,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (status) {
          if (Object.keys(data).length > 0) {
            data.token = loggedUser.token;
            setLoggedUser(data);
            userActions(data);
            setIsLoading(false);
            setError(null);
            handleCloseModal(true);
            showToast(true, paymentMessage);
            if (personalAccount) {
              navigate("/");
            } else {
              setIsOwnerPackageActive(true);
              setTimeout(() => {
                navigate("/team");
              }, 1000);
            }
          }
        } else {
          showToast(status, message);
          navigate("/");
        }
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      showToast(false, error.message);
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            border: "2px solid #333e56",
            padding: "10px",
            borderRadius: "50px",
          }}
          className="cardElementWrapper"
        >
          <CardElement id="card-element" options={cardElementOptions} />
        </div>
        <div className={classes.autoCheckbox}>
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Auto Subscription`}
            // onChange={handleAutoCheck}
            ref={checkbox}
          />
        </div>
        <div>
          {error && <p className="errorText">{error}</p>}
          <div className="text-center mt-4">
            <button
              type="submit"
              disabled={!stripe || isLoading}
              className={classes.payBtn}
            >
              {isLoading ? "Processing..." : "Pay"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default CheckoutForm;
